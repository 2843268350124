import React from 'react';
import MUIDataTable from 'mui-datatables';
import { createMuiTheme, makeStyles, MuiThemeProvider } from '@material-ui/core/styles';

const useStyles = makeStyles({
  centeredHeader: {
    '& span': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
});

export default ({
  tableData, selectedRow, setSelectedRow, networks, pageCondition,
}) => {
  const classes = useStyles();

  const columns = [
    {
      name: ['slope', 'bridge'].includes(pageCondition) ? 'Asset ID' : 'Road Name',
      selector: 'name',
      options: {
        setCellHeaderProps: () => ({ className: classes.centeredHeader }),
        setCellProps: () => ({ align: 'center' }),
      },
    },
    {
      name: 'Network',
      selector: 'network',
      options: {
        setCellHeaderProps: () => ({ className: classes.centeredHeader }),
        setCellProps: () => ({ align: 'center' }),
      },
    },
  ];

  const options = {
    filter: 'false',
    print: 'false',
    download: 'false',
    sort: 'false',
    viewColumns: 'false',
    search: 'false',
    tableBodyMaxHeight: '70vh',
    selectableRows: 'single',
    selectableRowsOnClick: true,
    onRowsDelete: () => false,
    rowsPerPage: tableData.length,
    customToolbarSelect: () => <div />,
    rowsSelected: selectedRow.map((row) => tableData.findIndex((item) => item?.id === row?.id)),
    onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
      const ids = rowsSelected.map((index) => tableData[index]);
      setSelectedRow(ids);
    },
  };

  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        options={options}
        columns={columns.map((col) => ({ ...col, label: col.name, name: col.selector }))}
        data={tableData.map((x) => {
          return ({
            ...x,
            name: x.name,
            network: networks.find(e => e.value === x.NetworkId)?.label ?? 'No Record',
          });
        })}
      />
    </MuiThemeProvider>
  );
};

const getMuiTheme = () => createMuiTheme({
  typography: {
    fontFamily: 'CeraProRegular',
  },
  overrides: {
    MuiToolbar: { root: { display: 'none' } },
    MUIDataTableSelectCell: {
      root: {
        display: 'none',
      },
    },
    MUIDataTableToolbarSelect: {
      root: {
        display: 'none',
      },
    },
    MuiTableHead: {
      root: {
        '& .MuiTableCell-root': {
          color: '#000000DE',
          fontSize: 18,
          fontWeight: 500,
        },
      },
    },
    MUIDataTableHeadCell: {
      toolButton: {
        justifyContent: 'center',
      },
    },
    MuiTableRow: {
      root: {
        color: '#048279',
      },
    },
    MuiTableCell: {
      body: {
        color: '#001847',
        fontSize: 14,
        fontWeight: 400,
      },
    },
  },
});
