/* eslint-disable guard-for-in */
import { useEffect, useState } from 'react';
import Api, { endpoints } from '@Helpers/api';
import _ from 'lodash';

export default function Hook() {
  const [isLoading, setIsLoading] = useState(false);
  const [allReports, setAllReports] = useState([]);
  const [filterReports, setFilterReports] = useState([]);
  const [networks, setNetworks] = useState([]);
  const [sections, setSections] = useState([]);
  const [regions, setRegions] = useState([]);
  const [rankings, setRankings] = useState([]);
  const [selectedCycleFilter, setSelectedCycleFilter] = useState([]);
  const [selectedYearFilter, setSelectedYearFilter] = useState([]);
  const [selectedNetworkFilter, setSelectedNetworkFilter] = useState([]);
  const [selectedRegionFilter, setSelectedRegionFilter] = useState([]);
  const [selectedSectionFilter, setSelectedSectionFilter] = useState([]);
  const [selectedRankingFilter, setSelectedRankingFilter] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedReport, setSelectedReport] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [openRestoreDialog, setOpenRestoreDialog] = useState(false);
  const [showDeleted, setShowDeleted] = useState(false);
  const [yearList, setYear] = useState([]);
  const cycle = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
  ];
  const filterType = {
    cycle: selectedCycleFilter,
    year: selectedYearFilter,
    'Asset.Network': selectedNetworkFilter,
    'Asset.Region': selectedRegionFilter,
    'Asset.Section': selectedSectionFilter,
    'Asset.Ranking': selectedRankingFilter,
  };

  useEffect(() => {
    setIsLoading(true);
    refresh();
    getStaticData();
  }, []);

  useEffect(() => {
    if (!allReports.length) return;
    refresh();
  }, [showDeleted]);

  const refresh = () => {
    setIsLoading(true);
    const data = showDeleted ? { deleted: 1 } : {};
    Api({
      endpoint: endpoints.getReports(),
      data,
      onSuccess: (response) => {
        const report = response.data.filter(f => !!f.Asset).map(e => ({
          'Asset.Network': e.Asset.NetworkId,
          'Asset.Region': e.Asset.RegionId,
          'Asset.Section': e.Asset.SectionId,
          'Asset.Ranking': e.Asset.RankingId,
          ...e,
        })).sort((a, b) => b.createdAt.localeCompare(a.createdAt));
        setAllReports(report);
        setFilterReports(report);
        const result = _.uniq(response.data.map(({ year }) => year)).sort((a, b) => a - b).map(e => ({ label: String(e), value: e }));
        setYear(result);
        setIsLoading(false);
      },
      onFail: (err) => {
        toast('error', err);
        setIsLoading(false);
      },
    });
  };

  const getStaticData = () => {
    Api({
      endpoint: endpoints.getStaticData(),
      onSuccess: ({ data }) => {
        setNetworks(data.Network.map(m => ({ label: m.name, value: m.id })));
        setRegions(data.Region.map(m => ({ label: m.name, value: m.id })));
        setSections(data.Section.map(m => ({ label: m.name, value: m.id })));
        setRankings(data.Ranking.map(m => ({ label: m.name, value: m.id })));
        setIsLoading(false);
      },
      onFail: (err) => { toast('error', err); setIsLoading(false); },
    });
  };

  const sortReport = (arr) => {
    return arr.sort((a, b) => (showDeleted ? b.deletedAt.localeCompare(a.deletedAt) : b.createdAt.localeCompare(a.createdAt)));
  };

  useEffect(() => {
    if (!allReports.length) return;
    const hasFilterType = (Object.keys(filterType).map(m => filterType[m].length)).filter(f => !!f);
    const selectedFilteredType = [];
    Object.keys(filterType).forEach(m => {
      if (filterType[m].length) selectedFilteredType.push({ [m]: filterType[m] });
    });
    const filteredTypeObj = Object.assign({}, ...selectedFilteredType);
    if (!hasFilterType.length) {
      setFilterReports(sortReport(allReports));
    } else {
      const result = allReports.filter(project => {
        // eslint-disable-next-line no-restricted-syntax
        for (const key in filteredTypeObj) {
          if (project[key] === undefined || !filteredTypeObj[key].includes(project[key])) return false;
        }
        return true;
      });
      setFilterReports(sortReport(_.uniq(result.flat())));
    }
  }, [allReports, selectedCycleFilter, selectedYearFilter, selectedNetworkFilter, selectedRegionFilter, selectedSectionFilter, selectedRankingFilter]);

  const downloadMultipleFile = () => {
    selectedRow.forEach(({ image }) => window.open(`${process.env.REACT_APP_FD}/${image}`));
  };

  const onDelete = () => {
    setIsLoading(true);
    Api({
      endpoint: endpoints.deleteReport(selectedReport.id),
      onSuccess: () => {
        toast('success', 'Successfully Delete ');
        setSelectedReport({});
        refresh();
      },
      onFail: () => {
        setSelectedReport({});
        setIsLoading(false);
        toast('error', 'Failed to Delete ');
      },
    });
  };

  const onRestoreReport = () => {
    setIsLoading(true);
    Api({
      endpoint: endpoints.restoreReport(selectedReport.id),
      onSuccess: () => {
        toast('success', 'Successfully Restore');
        setSelectedReport({});
        refresh();
      },
      onFail: () => {
        setSelectedReport({});
        setIsLoading(false);
        toast('error', 'Failed to Restore ');
      },
    });
  };

  return {
    isLoading,
    allReports,
    selectedCycleFilter,
    setSelectedCycleFilter,
    selectedYearFilter,
    setSelectedYearFilter,
    cycle,
    yearList,
    selectedRow,
    setSelectedRow,
    filterReports,
    networks,
    sections,
    regions,
    rankings,
    selectedNetworkFilter,
    setSelectedNetworkFilter,
    selectedRegionFilter,
    setSelectedRegionFilter,
    selectedSectionFilter,
    setSelectedSectionFilter,
    selectedRankingFilter,
    setSelectedRankingFilter,
    downloadMultipleFile,
    refresh,
    onDelete,
    selectedReport,
    setSelectedReport,
    openDialog,
    setOpenDialog,
    showDeleted,
    setShowDeleted,
    onRestoreReport,
    openRestoreDialog,
    setOpenRestoreDialog,
  };
}
