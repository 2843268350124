import { useEffect, useState } from 'react';
import Api, { endpoints } from '@Helpers/api';
import queryString from 'query-string';

export default function Hook({ project }) {
  const project_id = queryString.parse(window.location.search).id;
  const [reports, setReports] = useState([]);
  const [justUpdated, setJustUpdated] = useState(0);
  const [keys, setKeys] = useState('cycle');
  const [keyword, setKeyword] = useState('');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedReport, setSelectedReport] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [openRestoreDialog, setOpenRestoreDialog] = useState(false);
  const [showDeleted, setShowDeleted] = useState(false);
  const [reportVerify, setReportVerify] = useState(false);
  const [openVerifyDialogBeforeDownload, setOpenVerifyDialogBeforeDownload] = useState(false);
  const [openVerifyDialogAfterDownload, setOpenVerifyDialogAfterDownload] = useState(false);

  const refresh = () => {
    if (!project?.id || !project_id) return;
    setIsLoading(true);
    const data = { paginate: true };
    data.deleted = showDeleted ? 1 : null;
    data.page = page;
    data.perpage = perPage;
    data.sortby = showDeleted ? 'deletedAt,DESC' : 'createdAt,DESC';
    data.filter = `AssetId,${project?.id ?? project_id}${(!!keys && keyword) && `,${keys},${keyword}`}`;
    Api({
      endpoint: endpoints.getReports(),
      data,
      onSuccess: (response) => {
        setReports(response.data);
        setTotalData(response.total);
        setIsLoading(false);
      },
      onFail: () => {
        setReports([]);
        setIsLoading(false);
      },
    });
  };

  useEffect(() => {
    refresh();
  }, [project, justUpdated, perPage, page, showDeleted]);

  const onDelete = () => {
    setIsLoading(true);
    Api({
      endpoint: endpoints.deleteReport(selectedReport.id),
      onSuccess: () => {
        toast('success', 'Successfully Delete ');
        setJustUpdated(prev => prev + 1);
        setPage(prev => {
          if (!!(reports.length - 1)) return prev;
          return (!!(prev - 1) ? prev - 1 : 1);
        });
        setSelectedReport({});
      },
      onFail: () => {
        setIsLoading(false);
        toast('error', 'Failed to Delete ');
      },
    });
  };

  const onKeyDown = ({ key }) => {
    if (key === 'Enter') {
      setPage(1);
      refresh();
    }
  };

  useEffect(() => {
    setPage(1);
  }, [perPage]);

  const onRestoreReport = () => {
    setIsLoading(true);
    Api({
      endpoint: endpoints.restoreReport(selectedReport.id),
      onSuccess: () => {
        toast('success', 'Successfully Restore');
        setJustUpdated(prev => prev + 1);
        setPage(prev => {
          if (!!(reports.length - 1)) return prev;
          return (!!(prev - 1) ? prev - 1 : 1);
        });
        setSelectedReport({});
      },
      onFail: () => {
        setIsLoading(false);
        toast('error', 'Failed to Restore ');
      },
    });
  };

  const onReportVerify = () => {
    setIsLoading(true);
    Api({
      endpoint: endpoints.verifyReport(selectedReport.id),
      onSuccess: () => {
        toast('success', 'Report Verified');
        setSelectedReport({});
        setIsLoading(false);
        setJustUpdated(prev => prev + 1);
      },
      onFail: () => {
        setIsLoading(false);
        toast('error', 'Failed to Verify Report ');
      },
    });
  };

  return {
    reports,
    onDelete,
    keys,
    setKeys,
    keyword,
    setKeyword,
    page,
    setPage,
    perPage,
    setPerPage,
    onKeyDown,
    totalData,
    isLoading,
    selectedReport,
    setSelectedReport,
    openDialog,
    setOpenDialog,
    refresh,
    showDeleted,
    setShowDeleted,
    onRestoreReport,
    openRestoreDialog,
    setOpenRestoreDialog,
    reportVerify,
    setReportVerify,
    openVerifyDialogBeforeDownload,
    setOpenVerifyDialogBeforeDownload,
    openVerifyDialogAfterDownload,
    setOpenVerifyDialogAfterDownload,
    onReportVerify,
  };
}
