import { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import Table from '@Components/MaterialTable/v7';
import Navbar from '@Components/Navbar';
import NoDataImg from '@Assets/Images/Data-not-found3.svg';
import DeleteDialog from '@Components/DeleteDialog';
import MainContentContainer from '@Components/MainContentContainer';
import AvatarIcon from '@Components/AvatarIcon';
import PaginationV2 from '@Components/PaginationV2';
import CenteredLoadingContainer from '@Components/CenteredLoadingContainer';
import Upload3DDialog from '@Components/Upload3DDialog';
import { AccountCircle, AddOutlined } from '@material-ui/icons';
import Button from '@Components/Button';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { ActionBtn } from './Components';
import Hook from './hook';

const searchCategory = [
  { key: 'category', label: 'Category' },
  { key: 'name', label: '3D Modelling Name' },
];

export default function index(props) {
  const h = Hook(props);
  const [data, setData] = useState([]);

  const columns = [
    {
      name: 'No.',
      selector: 'id',
      options: {
        setCellHeaderProps: () => ({ style: { textAlign: 'left' } }),
        setCellProps: () => ({ style: { textAlign: 'left' } }),
        sort: false,
      },
    },
    {
      name: '3D Modelling Name',
      selector: 'name',
      options: {
        setCellHeaderProps: () => ({ align: 'left' }),
        setCellProps: () => ({ align: 'left' }),
        sort: false,
      },
    },
    {
      name: 'Model Category',
      selector: 'category',
      options: {
        setCellHeaderProps: () => ({ align: 'center' }),
        setCellProps: () => ({ align: 'center' }),
        sort: false,
      },
    },
    {
      name: 'Uploaded Date',
      selector: 'uploadedDate',
      options: {
        setCellHeaderProps: () => ({ align: 'center' }),
        setCellProps: () => ({ align: 'center' }),
        sort: false,
      },
    },
    {
      name: 'Upload By',
      selector: 'uploadedBy',
      options: {
        setCellHeaderProps: () => ({ align: 'center' }),
        setCellProps: () => ({ align: 'center' }),
        sort: false,
      },
    },
    {
      name: 'Recommendation',
      selector: 'actions',
      options: {
        setCellHeaderProps: () => ({ align: 'center' }),
        setCellProps: () => ({ align: 'center' }),
        sort: false,
      },
    },
  ];

  useEffect(() => {
    const modellingfFileData = h.modellingFiles?.map((m, idx) => ({
      ...m,
      id: idx + 1,
      name: <Link to={{ pathname: m.path }} target="_blank">{m.name}</Link>,
      category: h.modellingCategories.find(c => c.id === m.ModellingCategoryId)?.name,
      uploadedDate: moment(m.createdAt).format('DD MMMM YYYY'),
      uploadedBy: (
        <div className="flex-standard">
          {!!m.User ? <AvatarIcon user={m.User} size="2.5em" /> : <AccountCircle style={{ fontSize: '3em' }} />}
        </div>
      ),
      actions: <ActionBtn modellingFile={m} setOpen={h.setOpenDialog} setSelectedModellingFile={h.setSelectedModellingFile} />,
    }));
    setData(modellingfFileData);
  }, [h.modellingFiles, h.modellingCategories]);

  return (
    <MainContentContainer>
      <div style={{
        display: 'flex', alignItems: 'center', justifyContent: 'space-between',
      }}
      >
        <Navbar
          to="/project/"
          text="3D Modelling"
        />
        <Button
          variant="contained"
          style={{
            color: '#FFFFFF',
            borderRadius: 30,
            background: 'linear-gradient(var(--main-color), var(--primary-color))',
            width: '10rem',
          }}
          onClick={() => h.setOpenUploadDialog(true)}
        >
          <AddOutlined />
          <p className="text-white ml-2">Add 3D Model</p>
        </Button>
      </div>
      {h.isLoading ? <CenteredLoadingContainer height="50vh" size={75} hasText text="Documents" /> : (
        <Grid container>
          <Grid item xs={12}>
            <PaginationV2 keysList={searchCategory} totalData={h.totalData} onRefresh={h.refresh} {...h} />
          </Grid>
          <Grid item xs={12} style={{ padding: 20 }}>
            {data.length ? (
              <Table {...h} columns={columns} tableData={data} />
            ) : (
              <div className="d-flex justify-content-center">
                <img src={NoDataImg} style={{ width: '30vw' }} />
              </div>
            )}
          </Grid>
        </Grid>
      )}
      <DeleteDialog
        open={h.openDialog}
        setOpen={h.setOpenDialog}
        selected={h.selectedModellingFile}
        setSelected={h.setSelectedModellingFile}
        deleteFunction={() => h.onDelete()}
      />
      <Upload3DDialog
        open={h.openUploadDialog}
        setOpen={h.setOpenUploadDialog}
        name={h.fileName}
        url={h.fileURL}
        categoryList={h.modellingCategories}
        setName={h.setName}
        setURL={h.setPath}
        category={h.modellingCategoryId}
        setCategory={h.setModellingCategoryId}
        uploadFunction={h.onUploadModellingFile}
      />
    </MainContentContainer>
  );
}
