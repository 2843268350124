/* eslint-disable max-lines */
/* eslint-disable max-len */
import { useState, useEffect } from 'react';
import PolygonPicker from '@Components/MapV2/PolygonPickerEditAsset';
import {
  Grid, TextField, MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Dropzone from '@Components/DropzoneBox/index';
import { markerToString, stringToMarker } from '@Helpers';

const useStyles = makeStyles(() => ({
  gradient: {
    backgroundColor: 'var(--active-color)',
    backgroundImage: 'linear-gradient(var(--active-color), #33ABC1)',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
  },
  input: {
    backgroundColor: 'white',
    padding: 5,
    borderRadius: 5,
  },
  inputTag: {
    backgroundColor: 'white',
    paddingLeft: 20,
    paddingTop: 5,
    paddingBottom: 5,
    borderRadius: 5,
  },
  label: {
    marginTop: 13,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color: 'grey',
  },
  labelTag: {
    marginTop: 5,
    padding: 20,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color: 'grey',
  },
}));

export default (h) => {
  const classes = useStyles();
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (!h.network) return;
    if (h.network == 1) {
      setIsDisabled(false);
      return;
    }
    h.setRegion(null);
    h.setSection(null);
    setIsDisabled(true);
  }, [h.network]);

  return (
    <Grid container className="px-1 overflow-auto" style={{ maxHeight: '39.8rem' }}>
      <Grid item xs={12} className="px-1 mb-2">
        <Dropzone files={h.files} setFiles={h.setFiles} type="image" height={180} customStyle={{ backgroundColor: 'white', marginBottom: 10 }} />
        <br />
        <CustomTextField classes={classes} name="Asset Id" value={h.name} onChange={(e) => h.setName(e.target.value)} />
      </Grid>
      {[
        {
          title: 'Network',
          children: <CustomTextField classes={classes} name="Network" value={h.network} values={h.networks} onChange={(e) => h.setNetwork(e.target.value)} select />,
        },
        {
          title: 'Region',
          children: <CustomTextField classes={classes} name="Region" value={h.region} values={h.regions} onChange={(e) => h.setRegion(e.target.value)} select disabled={isDisabled} />,
        },
        {
          title: 'Section',
          children: <CustomTextField classes={classes} name="Section" value={h.section} values={h.sections} onChange={(e) => h.setSection(e.target.value)} select disabled={isDisabled} />,
        },
        {
          title: 'Locality of Feature',
          children: <CustomTextField classes={classes} name="Locality of Feature" value={h.locality} values={h.localities} onChange={(e) => h.setLocality(e.target.value)} select />,
        },
      ].map(({ children }) => (
        <Grid item xs={6} className="px-1 mb-2">
          {children}
        </Grid>
      ))}
      <Grid item xs={12} className="px-1 mb-2">
        <CustomTextField classes={classes} name="Route" value={h.route} values={h.routes} onChange={(e) => h.setRoute(e.target.value)} select />
      </Grid>
      <Grid item xs={12} className="px-1 mb-2">
        <CustomTextField classes={classes} name="Location (KM)" value={h.locationInKM} onChange={(e) => h.setLocationInKM(e.target.value)} type="number" />
      </Grid>
      {[
        {
          title: 'Feature Name',
          children: <CustomTextField classes={classes} name="Feature Name" value={h.featureName} values={h.featureNames} onChange={(e) => h.setFeatureName(e.target.value)} select />,
        },
        {
          title: 'Bridge Type',
          children: <CustomTextField classes={classes} name="Bridge Type" value={h.bridgeType} values={h.bridgeTypes} onChange={(e) => h.setBridgeType(e.target.value)} select />,
        },
        {
          title: 'Bridge Owner',
          children: <CustomTextField classes={classes} name="Bridge Owner" value={h.bridgeOwner} values={h.bridgeOwners} onChange={(e) => h.setBridgeOwner(e.target.value)} select />,
        },
        {
          title: 'No. of Span',
          children: <CustomTextField classes={classes} name="No. of Span" value={h.span} onChange={(e) => h.setSpan(e.target.value)} type="number" />,
        },
        {
          title: 'Total Bridge Length',
          children: <CustomTextField classes={classes} name="Total Bridge Length" value={h.bridgeLength} onChange={(e) => h.setBridgeLength(e.target.value)} type="number" />,
        },
        {
          title: 'Skew Angle',
          children: <CustomTextField classes={classes} name="Skew Angle" value={h.skewAngle} onChange={(e) => h.setSkewAngle(e.target.value)} type="number" />,
        },
        {
          title: 'Clearance',
          children: <CustomTextField classes={classes} name="Clearance" value={h.clearance} onChange={(e) => h.setClearance(e.target.value)} type="number" />,
        },
        {
          title: 'Coordinate',
          children: <CustomTextField classes={classes} name="Pin Coordinate" value={markerToString(h.marker)} onChange={(e) => h.setMarker(stringToMarker(e.target.value))} />,
        },
      ].map(({ children }) => (
        <Grid item xs={6} className="px-1 mb-2">
          {children}
        </Grid>
      ))}
      <Grid item xs={12} className="px-1">
        <Location {...h} classes={classes} />
      </Grid>
    </Grid>
  );
};

const Location = (h) => {
  return (
    <Grid container>
      <Grid item xs={6} className="pr-1" style={{ height: 300 }}>
        <PolygonPicker
          picker={h.marker}
          setPicker={h.setMarker}
          pickerFor="marker"
        />
      </Grid>
      <Grid item xs={6} className="pl-1" style={{ height: 300 }}>
        <PolygonPicker
          centerMap={h.marker}
          picker={h.polygon}
          setPicker={h.setPolygon}
          pickerFor="polygon"
          isLeafletDraw
        />
      </Grid>
    </Grid>
  );
};

const CustomTextField = (h) => {
  return (
    h.select ? (
      <TextField
        variant="outlined"
        fullWidth
        size="small"
        label={h.name}
        className="py-2"
        InputProps={{ className: h.classes.input }}
        InputLabelProps={{ className: h.classes.label }}
        {...h}
      >
        {h.values.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    ) : (
      <TextField
        placeholder={`Enter ${h.name} here`}
        variant="outlined"
        fullWidth
        size="small"
        label={h.name}
        className="py-2"
        InputProps={{ className: h.classes.input, inputProps: { min: 0 } }}
        InputLabelProps={{ className: h.classes.label }}
        {...h}
      />
    )
  );
};
