import React from 'react';
import {
  VictoryAxis, VictoryBar, VictoryChart, VictoryLabel, VictoryTooltip,
} from 'victory';

const data = [
  { x: 1, y: 0, fill: '#4FD8AF' },
  { x: 2, y: 0, fill: '#008FFB' },
  { x: 3, y: 0, fill: '#8B95AB' },
  { x: 4, y: 0, fill: '#FEB019' },
  { x: 5, y: 0, fill: '#EA1601' },
];

function BarChart({
  title = '', label = { x: 'x', y: 'y' }, category = { x: ['1', '2', '3', '4', '5'] }, tableData = data,
}) {
  return (
    <VictoryChart
      domainPadding={{ x: 15 }}
      padding={{
        top: 30, bottom: 110, right: 40, left: 50,
      }}
      animate={{
        duration: 2000,
        onLoad: { duration: 1000 },
      }}
    >
      <VictoryLabel
        text={title}
        x={225}
        y={15}
        textAnchor="middle"
        style={{ fill: '#022C64', fontSize: 13 }}
      />
      <VictoryBar
        labelComponent={<VictoryTooltip />}
        labels={({ datum }) => `${label.y}: ${category.x[datum.x - 1]}\nValue: ${datum.y}`}
        cornerRadius={{ top: 0 }}
        style={{ data: { fill: ({ datum }) => datum.fill } }}
        categories={category}
        data={tableData}
      />
      <VictoryAxis
        tickFormat={(x) => x}
        axisLabelComponent={<VictoryLabel verticalAnchor="start" style={{ fontSize: 10, color: 'grey' }} />}
      />
    </VictoryChart>
  );
}

export default BarChart;
