import {
  Box, Dialog, DialogActions, DialogContent, DialogTitle, Button,
} from '@material-ui/core';
import EditCalendar from '@Assets/Icons/EditCalendar.svg';
import React from 'react';
import { Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import InputItems from './ScheduleInspectionInput';
import InspectionReminderInput from './InspectionReminderInput';
import useHook from './hook';

const useStyles = makeStyles(() => ({
  blueBtn: { borderRadius: '6px', width: '150px' },
  blueBtnText: { color: '#FFFFFF', fontWeight: 600, fontSize: 16 },
  outlinedBtnText: { fontWeight: 600, fontSize: 16 },
  closeBtn: { cursor: 'pointer', float: 'right' },
  fontLabel: { fontSize: '10px' },
  formControl: { paddingTop: 10, paddingBottom: 5 },
  root: { '&$checked': { color: 'rgb(30, 52, 101)' }, transform: 'scale(0.8)' },
  dialogAction: {
    display: 'flex', justifyContent: 'space-between', padding: '0 20px', width: '100%',
  },
}));

export default function ScheduleInspection({ project, onSave }) {
  const classes = useStyles();
  const h = useHook({ project, onSave });
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'end', marginRight: 20 }}>
        <Button className="color-gradient-inline" style={{ borderRadius: 18 }} onClick={() => h.set_open(true)}>
          &nbsp;<img src={EditCalendar} />&nbsp;&nbsp;
          <p className="text-white">Schedule Next Inspection</p>
        </Button>
      </div>
      <Dialog
        open={h.open}
        onClose={h.handleClose}
        PaperProps={{ style: { borderRadius: 10, maxHeight: '40rem', width: 'auto' } }}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          Schedule Next Inspection
          <Close className={classes.closeBtn} onClick={h.handleClose} />
        </DialogTitle>
        <DialogContent style={{ overflowY: 'hidden' }}>
          <InputItems
            {...h}
            styles={classes.formControl}
          />
          <hr />
          <InspectionReminderInput {...h} styles={classes.formControl} />
        </DialogContent>
        <DialogActions>
          <div className={classes.dialogAction}>
            <Box
              component="button"
              className="blue-button-outlined"
              onClick={h.handleClose}
              style={{ borderRadius: '6px', width: '120px' }}
            >
              <h1 className={`${classes.outlinedBtnText} flex-standard`}>
                Cancel
              </h1>
            </Box>
            <Box
              component="button"
              className={`${classes.blueBtn} blue-button`}
              onClick={h.handleSubmit}
              style={{ borderRadius: '6px', width: '180px' }}
            >
              <h1 className={`${classes.blueBtnText} flex-standard`}>
                Schedule Inspection
              </h1>
            </Box>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}
