import { IconButton, Tooltip } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { DownloadIconV2 } from '@Assets/Icons';
import { Link } from 'react-router-dom';

export const ActionBtn = (props) => {
  return (
    <div className="d-flex justify-content-around align-items-center" style={{ gap: 0 }}>
      {!!props.report.image ? (
        <Link to={{ pathname: `${process.env.REACT_APP_FD}/${props.report.image}` }} target="_blank">
          <Tooltip title="Download Report" placement="top">
            <IconButton style={{ width: 16, height: 16 }}>
              <DownloadIconV2 />
            </IconButton>
          </Tooltip>
        </Link>
      ) : <p style={{ color: 'grey' }}>In Progress</p>}
      <DeleteButton {...props} />
    </div>
  );
};

const DeleteButton = ({ report, setOpen, setSelectedReport }) => (
  <Tooltip title="Delete Report" placement="top">
    <IconButton style={{ width: 16, height: 16 }} onClick={() => { setSelectedReport(report); setOpen(true); }}>
      <Delete style={{ color: 'red' }} />
    </IconButton>
  </Tooltip>
);
