import HeaderImg from '@Assets/Images/about-us.JPG';
import { Grid, Hidden } from '@material-ui/core';
import moment from 'moment';

function AboutUs() {
  return (
    <div
      style={{
        background: '#fff', flex: 1, height: '100%', minHeight: '100vh', position: 'relative',
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <div
            className="flex-standard"
            style={{ height: '35vh', background: `url(${HeaderImg}) no-repeat center` }}
          >
            <div>
              <p style={{ fontSize: 22, color: '#fff' }}>PLUS</p>
              <p style={{ fontSize: 24, fontWeight: 700, color: '#fff' }}>AUTOMATED HIGHWAY ASSET<br />DEFECT DETECTION (AHADD)</p>
            </div>
          </div>
        </Grid>
        <Grid container item xs={12} className="p-5">
          <Hidden mdUp> {/* mobile */}
            <Grid item xs={12} className="pr-4 mb-2">
              <h3 style={{ fontSize: 30, color: '#5B6272' }}>About&nbsp;Us&nbsp;&nbsp;</h3>
            </Grid>
          </Hidden>
          <Hidden smDown> {/* web */}
            <Grid item xs={6} md={4} justify="flex-end" className="pr-4 d-flex">
              <h3 style={{ fontSize: 30, color: '#5B6272' }}>About&nbsp;Us&nbsp;&nbsp;</h3>
            </Grid>
          </Hidden>
          <Grid item xs={12} md={6}>
            <div style={{ textAlign: 'justify' }}>
              <p style={{ color: '#5B6272', fontSize: 14 }}>
                IRIS is a cross-functional collaborative initiative used for monitoring and inspection of PLUS expressway assets. IRIS is an application born through Project Automated Highway Asset Defect Detection System (AHADD). It has the capability to perform automated defect detection and categorized it into different severity levels.
              </p>
            </div>
          </Grid>
        </Grid>
        <Grid container item xs={12} className="p-5">
          <Hidden mdUp> {/* mobile */}
            <Grid item xs={12} className="pr-4 mb-2">
              <h3 style={{ fontSize: 30, color: '#5B6272' }}>Disclaimer</h3>
            </Grid>
          </Hidden>
          <Hidden smDown> {/* web */}
            <Grid item xs={6} md={4} justify="flex-end" className="pr-4 d-flex">
              <h3 style={{ fontSize: 30, color: '#5B6272' }}>Disclaimer</h3>
            </Grid>
          </Hidden>
          <Grid item xs={12} md={6}>
            <div style={{ textAlign: 'justify' }}>
              <p style={{ color: '#5B6272', fontSize: 14 }}>
                The information only for intended recipient and shall not be disclosed or distributed to third party without the prior written consent of PLUS Berhad. Geomatic Operation cannot be held liable for the use of and reliance of the opinions, estimates and findings made by recipient from this application or its aerial footages.
              </p>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <div className="flex-standard position-absolute" style={{ bottom: 0, width: '100%' }}>
        <p style={{ fontSize: 11, textAlign: 'center' }}>
          {moment().year()} AHADD - Automated Highway Asset Defect Detection powered by&nbsp;
          <a href="https://ofo.my" target="_blank" rel="noopener noreferrer" className="color-text-primary">
            OFO Tech
          </a>
        </p>
      </div>
    </div>
  );
}

export default AboutUs;
