import { useEffect, useState } from 'react';

export default function Hook({ annotation, setMainImageAnnotations }) {
  const [justUpdate, setJustUpdate] = useState(false);
  // form data changes
  const handleChangeDescription = (e) => {
    setMainImageAnnotations(prevVal => prevVal
      .map(ann => (ann.id === annotation.id ? ({ ...ann, description: e.target.value }) : ann)));
  };
  const handleChangeSeverity = (e) => {
    setMainImageAnnotations(prevVal => prevVal
      .map(ann => (ann.id === annotation.id ? ({ ...ann, SeverityId: Number(e.target.value) }) : ann)));
    setJustUpdate(true);
  };
  const handleChangeDetectionType = (e) => {
    setMainImageAnnotations(prevVal => prevVal
      .map(ann => (ann.id === annotation.id ? ({ ...ann, DetectionTypeId: Number(e.target.value) }) : ann)));
    setJustUpdate(true);
  };
  const handleChangeAdditionalInfo = (value, attribute) => {
    setMainImageAnnotations(prevVal => prevVal
      .map(ann => (ann.id === annotation.id ? ({ ...ann, [attribute]: value }) : ann)));
  };

  useEffect(() => {
    if (!justUpdate) return;
    const description = {
      1: 'Defect is minor and require regular routine maintenance only.',
      2: 'Defect is minor and it is necessary to record the condition for the follow-up observation purposes.',
      3: 'Defect is slightly critical, and it is necessary to carry out routine maintenance works.',
      4: 'Defect is critical and requires repair works or further investigation (to determine whether rehabilitation work is necessary).',
      5: 'Defect is critically severe and possibly affecting the safety of traffic. Emergency temporary or urgent repair need to be implemented.',
      6: 'Defect is minor and require regular routine maintenance only.',
      7: 'Defect is minor and it is necessary to record the condition for the follow-up observation purposes.',
      8: 'Defect is slightly critical, and it is necessary to carry out routine maintenance works.',
      9: 'Defect is critically severe and possibly affecting the safety of traffic. Emergency temporary or urgent repair need to be implemented.',
    }[annotation?.SeverityId];
    setMainImageAnnotations(prevVal => prevVal
      .map(ann => (ann.id === annotation.id ? ({ ...ann, description }) : ann)));
  }, [annotation?.SeverityId]);

  useEffect(() => {
    if (!justUpdate) return;
    const getSeverityId = (key) => {
      if (key >= 15 && key <= 51) return 6;
      if (key >= 10) return 1;
      else {
        const SeverityId = {
          1: 4,
          2: 2,
          3: 3,
          4: 3,
          5: 4,
          6: 3,
          7: 4,
          8: 2,
          9: 2,
        };
        return SeverityId[key];
      }
    }
    setMainImageAnnotations(prevVal => prevVal
      .map(ann => (ann.id === annotation.id ? ({ ...ann, SeverityId: getSeverityId(annotation?.DetectionTypeId) }) : ann)));
  }, [annotation?.DetectionTypeId]);

  return {
    handleChangeDescription,
    handleChangeSeverity,
    handleChangeDetectionType,
    handleChangeAdditionalInfo,
  };
}
