import React from 'react';
import {
  DialogTitle, Dialog, DialogContent, DialogActions, Tooltip, IconButton, TextField,
} from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import Button from '@Components/Button';
import Dropzone from './DropzoneBox/v3';

export default function UploadDocumentDialog({
  open, setOpen, files, setFiles, uploadFunction = () => null, progress, title, no, date, setTitle, setNo, setDate,
}) {
  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
        <div className="d-flex justify-content-between align-items-center">
          <DialogTitle>
            <p style={{ color: 'var(--dark-blue-color)', fontWeight: 600, fontSize: 20 }}>Upload Document</p>
          </DialogTitle>
          <IconButton style={{ backgroundColor: 'transparent', padding: 0 }}>
            <Close style={{ color: 'grey', padding: 20 }} onClick={() => setOpen(false)} />
          </IconButton>
        </div>
        <DialogContent style={{ textAlign: 'center' }}>
          <Dropzone
            files={files}
            setFiles={setFiles}
            type="pdfImg"
            height={100}
            maxFiles={1}
            customStyle={{ backgroundColor: 'white', marginBottom: 10 }}
            checkLocation
            progress={progress}
          />
          {[
            {
              inputLabel: 'Document Title', value: title, placeholder: 'Enter document title here', setValue: setTitle,
            },
            {
              inputLabel: 'Document No', value: no, placeholder: 'Enter document title here', setValue: setNo,
            },
            {
              inputLabel: 'Document Date', value: date, placeholder: 'Enter document title here', type: 'date', setValue: setDate,
            },
          ].map(d => (
            <div style={{ width: '100%', marginTop: 20 }}>
              <p style={{
                display: 'flex', flex: 'start', marginBottom: 5, color: 'var(--dark-blue-color)',
              }}
              >{d.inputLabel}
              </p>
              <TextField
                {...d}
                placeholder={d.placeholder}
                variant="outlined"
                fullWidth
                value={d.value}
                onChange={(e) => d.setValue(e.target.value)}
              />
            </div>
          ))}
        </DialogContent>
        <DialogActions style={{ justifyContent: 'end', marginBlock: 10, marginRight: 20 }}>
          <Button
            variant="contained"
            style={{
              color: 'var(--primary-color)',
              width: '10rem',
              outline: '1px solid var(--primary-color)',
              backgroundColor: 'transparent',
              borderRadius: 30,
            }}
            onClick={() => setOpen(false)}
          >
            <p className="ml-2">Cancel</p>
          </Button>
          <Button
            variant="contained"
            style={{
              color: '#FFFFFF',
              borderRadius: 30,
              background: 'linear-gradient(var(--main-color), var(--primary-color))',
              width: '10rem',
            }}
            onClick={uploadFunction}
          >
            <p className="text-white ml-2">Upload</p>
          </Button>
        </DialogActions>
      </Dialog>
    </MuiThemeProvider>
  );
}

const getMuiTheme = () => createMuiTheme({
  typography: {
    fontFamily: 'CeraProRegular',
  },
});
