/* eslint-disable complexity */
import React from 'react';
import MUIDataTable from 'mui-datatables';
import { createMuiTheme, makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import moment from 'moment';
import AvatarIcon from '@Components/AvatarIcon';
import { AccountCircle, Delete } from '@material-ui/icons';
import { IconButton, Tooltip } from '@material-ui/core';
import restoreIcon from '@Assets/Icons/restore_icon.svg';

const useStyles = makeStyles({
  centeredHeader: {
    '& span': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
});

export default ({
  tableData, selectedRow, setSelectedRow, setOpenDialog, setOpenRestoreDialog, setSelectedReport, showDeleted,
}) => {
  const classes = useStyles();

  const columns = [
    {
      name: 'Number',
      selector: 'id',
      options: {
        setCellHeaderProps: () => ({ className: classes.centeredHeader }),
        setCellProps: () => ({ align: 'center' }),
      },
    },
    {
      name: 'Asset Name',
      selector: 'asset_name',
      options: {
        setCellHeaderProps: () => ({ className: classes.centeredHeader }),
        setCellProps: () => ({ align: 'center' }),
      },
    },
    {
      name: 'Report Name',
      selector: 'name',
      options: {
        setCellHeaderProps: () => ({ className: classes.centeredHeader }),
        setCellProps: () => ({ align: 'center' }),
      },
    },
    {
      name: 'Cycle',
      selector: 'cycle',
      options: {
        setCellHeaderProps: () => ({ className: classes.centeredHeader }),
        setCellProps: () => ({ align: 'center' }),
      },
    },
    {
      name: 'Year',
      selector: 'year',
      options: {
        setCellHeaderProps: () => ({ className: classes.centeredHeader }),
        setCellProps: () => ({ align: 'center' }),
      },
    },
    {
      name: 'Inspection By',
      selector: 'inspectionBy',
      options: {
        setCellHeaderProps: () => ({ className: classes.centeredHeader }),
        setCellProps: () => ({ align: 'center' }),
      },
    },
    {
      name: 'Report Generated',
      selector: 'inspectionAt',
      options: {
        setCellHeaderProps: () => ({ className: classes.centeredHeader }),
        setCellProps: () => ({ align: 'center' }),
      },
    },
    {
      name: 'Generated by',
      selector: 'userimage',
      options: {
        setCellHeaderProps: () => ({ align: 'center' }),
        setCellProps: () => ({ align: 'center' }),
        sort: false,
      },
    },
    {
      name: 'Report Status',
      selector: 'status',
      options: {
        setCellHeaderProps: () => ({ align: 'center' }),
        setCellProps: () => ({ align: 'center' }),
        sort: false,
        display: showDeleted === false,
      },
    },
    {
      name: 'Archive Status',
      selector: 'archive_status',
      options: {
        setCellHeaderProps: () => ({ align: 'center' }),
        setCellProps: () => ({ align: 'center' }),
        sort: false,
        display: showDeleted === true,
      },
    },
    {
      name: 'Action',
      selector: 'action',
      options: {
        setCellHeaderProps: () => ({ align: 'center' }),
        setCellProps: () => ({ align: 'center' }),
        sort: false,
      },
    },
  ];

  const options = {
    filter: 'false',
    filterType: 'multiselect',
    print: 'false',
    download: 'false',
    sort: 'false',
    viewColumns: 'false',
    search: 'false',
    tableBodyMaxHeight: '65vh',
    onRowsDelete: () => false,
    customToolbarSelect: () => <div />,
    rowsSelected: selectedRow.map((row) => tableData.findIndex((item) => item.id === row.id)),
    onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
      const ids = rowsSelected.map((index) => tableData[index]).filter(e => !!e.image);
      setSelectedRow(ids);
    },
  };

  const statusColors = {
    "Ready": "green",
    "Failed": "red",
    "In Progress": "grey"
  };

  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        options={options}
        columns={columns.map((col) => ({ ...col, label: col.name, name: col.selector }))}
        data={tableData.map((x, idx) => {
          let status=!x.deletedAt && !!x.image ? 'Ready' : 'In Progress';
          if (status === "In Progress" && moment().isAfter(moment(x.createdAt).add(2, 'hours'))) {
            status = "Failed";
          }
          return ({
            ...x,
            id: idx + 1,
            asset_name: x.Asset.name,
            name: x.name,
            cycle: x.cycle,
            year: x.year,
            inspectionAt: moment(x.createdAt).format('DD/MM/YYYY hh:mm a'),
            inspectionBy: (
              <div className="flex-standard">
                {!!x.Inspection?.pilot_name ? (
                  <AvatarIcon
                    user={{
                      name: x.Inspection.pilot_name.substring(0, x.Inspection.pilot_name.indexOf('@')),
                      image: x.Inspection.image,
                    }}
                    size="2.5em"
                  />
                ) : <AccountCircle style={{ fontSize: '3em' }} />}
              </div>
            ),
            userimage: (
              <div className="flex-standard">
                {!!x.User ? <AvatarIcon user={x.User} size="2.5em" /> : <AccountCircle style={{ fontSize: '3em' }} />}
              </div>
            ),
            status:  <p style={{ color: statusColors[status] }}>{status}</p>,
            archive_status: !!x.deletedAt && moment(x.deletedAt).fromNow(),
            action: (
              !!x.deletedAt ? <RestoreButton report={x} setOpen={setOpenRestoreDialog} setSelectedReport={setSelectedReport} />
                : <DeleteButton report={x} setOpen={setOpenDialog} setSelectedReport={setSelectedReport} />
            ),
          });
        })}
      />
    </MuiThemeProvider>
  );
};

const DeleteButton = ({ report, setOpen, setSelectedReport }) => (
  <Tooltip title="Delete Report" placement="top" onClick={() => { setSelectedReport(report); setOpen(true); }}>
    <IconButton style={{ width: 16, height: 16 }}>
      <Delete style={{ color: 'red' }} />
    </IconButton>
  </Tooltip>
);

const RestoreButton = ({ report, setOpen, setSelectedReport }) => {
  return (
    <Tooltip title="Restore Report" placement="top" onClick={() => { setSelectedReport(report); setOpen(true); }}>
      <IconButton style={{ width: 16, height: 16 }}>
        <img src={restoreIcon} />
      </IconButton>
    </Tooltip>
  );
};

const getMuiTheme = () => createMuiTheme({
  typography: {
    fontFamily: 'CeraProRegular',
  },
  overrides: {
    MuiTableHead: {
      root: {
        '& .MuiTableHead-root': {
          color: '#000000DE',
          fontSize: 14,
          fontWeight: 500,
          verticalAlign: 'middle',
        },
        '& .MuiTableCell-root': {
          color: '#000000DE',
          fontSize: 14,
          fontWeight: 500,
          verticalAlign: 'middle',
        },
      },
    },
    MUIDataTableHeadCell: {
      toolButton: {
        margin: '0 !important',
      },
    },
  },
});
