/* eslint-disable max-len */
export const KMMarker = ({ label = '1' }) => {
  const svgWidth = 55;
  const labelWidth = 11;
  const labelLength = {
    1: 0.9,
    2: 1.1,
    3: 1.25,
    4: 1.45,
  }[String(label).length];
  const centerX = (svgWidth / 2 - labelWidth / 2) / labelLength;
  return (
    <svg width={svgWidth} height="26" viewBox={`0 0 ${svgWidth} 26`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 20.4717V5.98113L27.5263 1L54.0526 5.98113V20.4717L27.5263 25L1 20.4717Z" fill="url(#paint0_linear_922_9)" stroke="#DDBA4F" strokeWidth="1.89474" />
      <text fill="black" xmlSpace="preserve" style={{ whiteSpace: 'pre' }} fontFamily="Inter" fontSize="11" fontWeight="900" letterSpacing="0em">
        <tspan x={centerX} y="16.5">{label}</tspan>
      </text>
      <defs>
        <linearGradient id="paint0_linear_922_9" x1="27.5263" y1="1" x2="27.5263" y2="25" gradientUnits="userSpaceOnUse">
          <stop offset="0.245261" stopColor="#FADD59" />
          <stop offset="1" stopColor="#B79600" />
        </linearGradient>
      </defs>
    </svg>
  );
};
