/* eslint-disable max-len */
/* eslint-disable complexity */
import Button from '@Components/Button';
import { Grid } from '@material-ui/core';
import AssetCard from '../Component/AssetCard';
import BridgeForm from './FormInput/BridgeForm';
import SlopeForm from './FormInput/SlopeForm';

export default (h) => {
  let isDisabled;

  switch (h.selectedTypeProfile.id) {
    case 1:
      isDisabled = !!h.name && !!h.marker?.lat && !!h.marker?.lng && !!h.polygon && !!h.network?.value && !!h.ranking?.value;
      break;
    case 2:
      isDisabled = !!h.name && !!h.marker.lat && !!h.marker.lng && !!h.polygon && !!h.locality && !!h.route && !!h.locationInKM && !!h.featureName && !!h.bridgeType && !!h.bridgeOwner && !!h.span && !!h.bridgeLength && !!h.skewAngle && !!h.clearance;
      break;
    default:
      isDisabled = false; // or any other default value
  }

  return (
    <div className="mx-auto" style={{ width: '90%', background: 'linear-gradient(45deg, var(--primary-color), var(--main-color))', padding: 20 }}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <AssetCard {...h} data={h.selectedTypeProfile} view />
        </Grid>
        <Grid item xs={9}>
          {{
            1: <SlopeForm {...h} />,
            2: <BridgeForm {...h} />,
          }[h.selectedTypeProfile.id]}
        </Grid>
      </Grid>
      <div className="d-flex justify-content-end mt-5" style={{ gap: 10 }}>
        <Button variant="text" onClick={h.handleBackStep} size="small">
          PREVIOUS
        </Button>
        <Button className={`color-gradient-${!isDisabled ? 'disabled' : 'inline'}`} disabled={!isDisabled} onClick={h.handleNextStep} size="small">
          NEXT
        </Button>
      </div>
    </div>
  );
};
