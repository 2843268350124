import { Button } from 'reactstrap';
import microsoftIcon from '@Assets/Icons/microsoftIcon.svg';

export default function LoginFormMobile() {
  const loginUrl = `${window.location.origin}/login`;
  return (
    <>
      <Button
        className="d-none d-sm-block w-50 my-3 p-1"
        style={{ backgroundColor: '#fff', borderRadius: '5px' }}
        onClick={() => window.location.replace(`${process.env.REACT_APP_ENDPOINT_URL}auth/microsoft?redirect_url=${loginUrl}`)}
        children={(
          <div
            className="d-flex align-items-center"
            style={{
              justifyContent: 'space-evenly', color: '#5B6272', fontWeight: 600, fontSize: '2vh',
            }}
          >
            <img src={microsoftIcon} style={{ width: '2vw' }} />
            SIGN IN VIA MICROSOFT
          </div>
        )}
      />
      <Button
        className="d-block d-sm-none w-75 my-3 p-1"
        style={{ backgroundColor: '#fff', borderRadius: '5px' }}
        onClick={() => window.location.replace(`${process.env.REACT_APP_ENDPOINT_URL}auth/microsoft?redirect_url=${loginUrl}`)}
        children={(
          <div
            className="d-flex align-items-center"
            style={{
              justifyContent: 'space-evenly', color: '#5B6272', fontWeight: 600, fontSize: '2vh',
            }}
          >
            <img src={microsoftIcon} style={{ width: '4vw' }} />
            SIGN IN VIA MICROSOFT
          </div>
        )}
      />
    </>
  );
}
