/* eslint-disable max-len */
import Button from '@Components/Button';
import { Grid } from '@material-ui/core';
import MainContentContainer from '@Components/MainContentContainer';
import MainContentNavbar from '@Components/MainContentNavbar';
import CenteredLoadingContainer from '@Components/CenteredLoadingContainer';
import AssetCard from './Component/AssetCard';
import FormInput from './FormInput';
import FormInputBridge from './FormInputBridge';
import useHook from './hook';

// eslint-disable-next-line complexity
export default () => {
  const h = useHook();
  let isDisabled;

  switch (h.selectedTypeProfile?.id) {
    case 1:
      isDisabled = !!h.name && !!h.marker?.lat && !!h.marker?.lng && !!h.polygon && !!h.network && !!h.ranking;
      break;
    case 2:
      isDisabled = !!h?.name && !!h.marker?.lat && !!h.marker?.lng && !!h?.polygon && !!h?.locality && !!h?.route && !!h?.locationInKM && !!h?.featureName && !!h?.bridgeType && !!h?.bridgeOwner && !!h?.span && !!h?.bridgeLength && !!h?.skewAngle && !!h?.clearance.toString().length && !!h.network;
      break;
    default:
      isDisabled = false; // or any other default value
  }
  return (
    <MainContentContainer style={{ minHeight: '90vh', marginTop: '1rem' }}>
      <MainContentNavbar text="Edit Asset Information" />
      {!!h.isLoading ? (
        <CenteredLoadingContainer height="50vh" size={75} hasText text="asset" />
      ) : (
        <div
          className="mx-auto"
          style={{
            width: '90%', background: 'linear-gradient(45deg, var(--primary-color), var(--main-color))', padding: 20, borderRadius: 10,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <AssetCard {...h} data={h.selectedTypeProfile} view />
            </Grid>
            <Grid item xs={9}>
              {{
                1: <FormInput {...h} />,
                2: <FormInputBridge {...h} />,
              }[h.selectedTypeProfile?.id]}
            </Grid>
          </Grid>
          <div className="d-flex justify-content-end mt-5" style={{ gap: 10 }}>
            <Button className={`color-gradient-${!isDisabled ? 'disabled' : 'inline'}`} disabled={!isDisabled} onClick={h.updateAsset}>
              SAVE CHANGES
            </Button>
          </div>
        </div>
      )}
    </MainContentContainer>
  );
};
