import moment from 'moment';
import React from 'react';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryGroup, VictoryLabel, VictoryLegend, VictoryTheme, VictoryTooltip } from 'victory';

const GroupBarChart = ({
  height = 400, width = 400, offset = 0, data = [], legendX = 0, withDate = false,
}) => {
  return (
    <VictoryChart
      height={height}
      width={width}
      padding={{ top: 55, bottom: 150, left: 50, right: 40 }}
    >
      <VictoryLegend
        x={legendX}
        y={10}
        centerTitle
        orientation="horizontal"
        gutter={20}
        style={{ labels: { fontSize: 18 } }}
        data={[
          { name: "North Bound", symbol: { fill: "#32AAD2", type: "square" } },
          { name: "South Bound", symbol: { fill: "#51DBA5", type: "square" } },
        ]}
      />
      <VictoryAxis
        dependentAxis
        crossAxis
        theme={VictoryTheme.material}
        style={{ grid: { stroke: 'grey', strokeOpacity: '50%' } }}
      />
      <VictoryAxis
        crossAxis
        theme={VictoryTheme.material}
        style={{
          tickLabels: {
            fill: ({ text }) => [moment().format('ddd'), moment().format('MMM')].includes(text.split('-')[0]) ? 'white' : 'black',
          }
        }}
        tickLabelComponent={<CustomTickLabel withDate={withDate} />}
      />
      <VictoryGroup
        offset={offset}
        colorScale={["#32AAD2", "#51DBA5"]}
      >
        {data.map(e => (
          <VictoryBar
            data={e.data}
            cornerRadius={7}
            labels={({ datum }) => datum.y}
            labelComponent={<VictoryTooltip constrainToVisibleArea />}
          />
        ))}
      </VictoryGroup>
    </VictoryChart>
  )
}

export default GroupBarChart;

const CustomTickLabel = ({ x, y, text, withDate, ...props }) => {
  const displayText = withDate ? text.replace('-', '\n') : text;

  const textPaddingX = 20; // Adjust padding around the text
  const textPaddingY = 10; // Adjust padding around the text
  const textStyle = { fontSize: 12 };

  // Split the text into lines based on line breaks
  const lines = displayText.split('\n');

  // Create a temporary canvas to measure text size
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = `${textStyle.fontSize}px ${textStyle.fontFamily || 'inherit'}`;

  // Calculate the maximum line width
  const maxWidth = lines.reduce((max, line) => {
    const lineWidth = context.measureText(line).width;
    return lineWidth > max ? lineWidth : max;
  }, 0);

  const lineHeight = textStyle.fontSize * 1.2; // Approximate text height

  if ([moment().format('ddd'), moment().format('MMM')].includes(text.split('-')[0])) {
    return (
      <g>
        <rect
          x={x - maxWidth / 2 - textPaddingX / 2} // Center rect based on max line width
          y={y - lines.length * (lineHeight / (withDate ? 3 : 1.5)) - textPaddingY / 2 + 10} // Center rect based on total height
          width={maxWidth + textPaddingX}
          height={lines.length * lineHeight + textPaddingY}
          rx={5}
          ry={5}
          fill="#A7A7A7"
        />
        {lines.map((line, index) => (
          <VictoryLabel
            key={index}
            {...props}
            text={line}
            x={x}
            y={y - lines.length * (lineHeight / (withDate ? 2 : 10)) + index * lineHeight + (withDate ? 15 : 0)}
            textAnchor="middle"
          />
        ))}
      </g>
    )
  };

  return (
    <VictoryLabel
      {...props}
      text={displayText}
      x={x}
      y={y}
      textAnchor="middle"
    />
  );
};