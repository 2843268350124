/* eslint-disable complexity */
import React, { useState } from 'react';
import {
  Card, Grid, Typography, CardActionArea, CardMedia, CardContent, Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { RankingAhaddIcon } from '@Assets/Icons/RankingAhaddIcon';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { BridgeTypeIcon } from '@Assets/Icons/BridgeTypeIcon';

const useStyles = makeStyles({
  root: { maxWidth: '100%', margin: '0 2px', minHeight: '100%' },
  media: { height: '30vh' },
  title: {
    fontWeight: 'bold', fontSize: 20, color: 'var(--dark-blue-color)', fontFamily: 'CeraProRegular',
  },
  content: { color: '#707070', fontSize: 12, fontFamily: 'CeraProRegular' },
  deleteButtonContent: { right: 15, top: '30%' },
  avatarContent: { right: 10, top: '20%' },
  deleteButton: { color: '#FF1E1E' },
  editButtonContent: { position: 'absolute', right: 11, top: 11 },
  editButton: {
    color: '#045C5C', backgroundColor: '#FFFFFF', borderRadius: '50%', padding: 2,
  },
});

export default function AssetCard({
  data: projects, setSelectedAsset, setOpen, userData, setOpenRelative,
  setWorkflowAccess, setTeamAccess, setUserAccess, ...h
}) {
  const classes = useStyles();
  const project_img = `${process.env.REACT_APP_FD}/${!!projects?.image ? projects.image : 'static/media/defaultAssetImg-01.png'}`;
  const region = h.regions.find(f => f.value === projects.RegionId)?.label;
  const network = h.networks.find(f => f.value === projects.NetworkId)?.label;
  const section = h.sections.find(f => f.value === projects.SectionId)?.label;
  const [onHover, setOnHover] = useState(false);
  return (
    <Grid item xs={6} sm={4} md={3} lg={3} className="mt-2 position-relative" key={projects?.id}>
      <>
        <div
          className="position-absolute"
          onMouseOver={() => setOnHover(true)}
          onMouseLeave={() => setOnHover(false)}
          onFocus={() => setOnHover(true)}
          style={{
            position: 'absolute',
            zIndex: 99,
            top: 10,
            left: 10,
            transform: onHover ? 'scale(0.94)' : 'scale(0.8)',
            transition: 'all .5s',
          }}
        >
          {{
            1: <RankingAhaddIcon type={projects.RankingId} />,
            2: <BridgeTypeIcon type={projects.bridge_type} />,
          }[projects.AssetTypeId]}
        </div>
      </>
      <Card
        onMouseOver={() => setOnHover(true)}
        onMouseLeave={() => setOnHover(false)}
        className={classes.root}
        style={{ transform: onHover ? 'scale(1.01)' : 'scale(1)', transition: 'all .5s' }}
      >
        <CardActionArea>
          <Link to={`/project?id=${projects?.id}`}>
            <CardMedia className={classes.media} image={project_img} />
          </Link>
          <Grid item className={classes.editButtonContent}>
            <Button
              onClick={() => {
                h.setOpenCardDialog(true);
                setSelectedAsset(projects);
              }}
            >
              <h3 className="text-white mr-1 h-25" style={{ fontSize: 16 }}>• • •</h3>
            </Button>
          </Grid>
          <CardContent className="position-relative p-0">
            <Link to={`/project?id=${projects?.id}`}>
              <div style={{ padding: 16 }}>
                <Typography gutterBottom className={classes.title}>{projects?.name}</Typography>
                <Typography component="p" className={classes.content}>
                  {`${!!region ? `${region},` : ''} ${network}${!!section ? `, ${section}` : ''}`}
                  <br />
                  {`Last Update on ${moment(projects?.updatedAt).format('D MMMM YYYY, hh:mm A')}`}
                </Typography>
              </div>
            </Link>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
}
