import React from 'react';
import { makeStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import {
  Chip, Checkbox,
} from '@material-ui/core';
import _ from 'lodash';

const useStyles = makeStyles(() => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
}));

export default function FilterAssetTag(h) {
  const classes = useStyles();
  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      {[{ label: 'All', value: '0' }, ...h.tags.map(tg => ({ ...tg, value: String(tg.value) }))]?.map(tag => (
        <Checkbox
          id="tags"
          onClick={e => {
            h.setTag(pTags => {
              if (e.target.checked) pTags.push(e.target.value);
              else pTags = pTags.filter(pt => pt != e.target.value);
              pTags = _.uniq(pTags);
              return pTags;
            });
          }}
          classes={{ root: classes.root }}
          value={tag.value}
          checked={h.tag.includes(tag.value)}
          icon={(
            <Chip
              label={tag.label}
              size="small"
              style={{
                backgroundColor: 'white', color: 'var(--main-color)',
              }}
              className=" mx-1 text-uppercase"

            />
          )}
          checkedIcon={(
            <Chip
              label={tag.label}
              size="small"
              style={{
                color: 'white',
              }}
              className="color-gradient-inline mx-1 text-uppercase"
            />
          )}
        />
      ))}
    </MuiThemeProvider>
  );
}

const getMuiTheme = () => createMuiTheme({
  typography: {
    fontFamily: 'CeraProRegular',
  },
});
