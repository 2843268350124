import SearchBox from '@Components/SearchBox/v2';
import {
  DialogContent, DialogTitle, Grid, Popover, Tooltip,
} from '@material-ui/core';
import Table from '@Components/MaterialTable/v10';
import FilterIcon from '@Assets/Icons/filter-solid.svg';
import { useState } from 'react';
import SortDropdown from '@Components/SortDropdown';


export default function AssetTable(h) {
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <Grid container item direction="row" xs={3} alignItems="stretch">
      <Grid item xs={12} style={{ height: '88vh' }}>
        <div className="paper-shadow bg-white rounded h-100">
          <div className="p-3" style={{ backgroundColor: 'var(--primary-color)' }}>
            <h4 style={{ color: 'white', fontSize: 16 }}>{`${['slope', 'bridge'].includes(h.pageCondition) ? 'Asset': 'Detection'} List`}</h4>
          </div>
          <Grid container className="p-2">
            <Grid item xs={12} className="d-flex align-items-center">
              <SearchBox onChange={(e) => h.setKeyword(e.target.value)} />
              <Tooltip title="Filter">
                <img
                  src={FilterIcon}
                  style={{ cursor: 'pointer', width: '20px', paddingLeft: 10 }}
                  onClick={(event) => setAnchorEl(anchorEl ? null : event.currentTarget)}
                />
              </Tooltip>
              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                <DialogTitle className="py-2">
                  Filter Asset
                </DialogTitle>
                <DialogContent dividers style={{ minWidth: '33rem' }}>
                  {[
                    {
                      selected: h.selectedNetwork, setSelected: h.setSelectedNetwork, data: h.networks, title: 'Networks',
                    },
                    {
                      selected: h.selectedSection, setSelected: h.setSelectedSection, data: h.sections, title: 'Sections',
                    },
                    {
                      selected: h.selectedRegion, setSelected: h.setSelectedRegion, data: h.regions, title: 'Regions',
                    },
                    {
                      selected: h.selectedRanking, setSelected: h.setSelectedRanking, data: h.rankings, title: 'Slope Hazard & Risk Rankings', type: 'slope',
                    },
                    {
                      selected: h.selectedBridgeType, setSelected: h.setSelectedBridgeType, data: h.bridgeType, title: 'Bridge Type', type: 'bridge',
                    },
                  ].filter(e => !e.type || (e.type === h.pageCondition)).map(m => <SortDropdown {...m} />)}
                </DialogContent>
              </Popover>
            </Grid>
          </Grid>
          <div className="w-100 overflow-auto border-top">
            <Table tableData={h.filterAsset} {...h} />
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
