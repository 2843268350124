import React from 'react';
import MUIDataTable from 'mui-datatables';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

const options = {
  filter: false,
  filterType: 'dropdown',
  print: false,
  download: false,
  responsive: 'standard',
  selectableRows: 'none',
  sort: true,
  elevation: 0,
  pagination: false,
  search: false,
  viewColumns: false,
  tableBodyMaxHeight: '80vh',
};

export default (props) => {
  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        options={options}
        columns={props.columns.map((col) => ({ ...col, label: col.name, name: col.selector }))}
        data={props.tableData}
      />
    </MuiThemeProvider>
  );
};

const getMuiTheme = () => createMuiTheme({
  typography: {
    fontFamily: 'CeraProRegular',
  },
  overrides: {
    MuiTableHead: {
      root: {
        '& .MuiTableHead-root': {
          color: '#000000DE',
          fontSize: 14,
          fontWeight: 500,
          verticalAlign: 'middle',
        },
        '& .MuiTableCell-root': {
          color: '#000000DE',
          fontSize: 14,
          fontWeight: 500,
          verticalAlign: 'middle',
        },
      },
    },
    MuiTableRow: {
      root: {
        color: '#048279',
      },
    },
  },
});
