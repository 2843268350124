/* eslint-disable max-len */
// reference: https://github.com/Azure-Samples/azure-maps-leaflet
import * as L from 'leaflet';

const azure_url = 'https://atlas.microsoft.com/map/tile?subscription-key={subscriptionKey}&api-version=2.0&tilesetId={tilesetId}&zoom={z}&x={x}&y={y}&tileSize=256&language={language}&view={view}';
const azure_map_data = {
  attribution: `© ${new Date().getFullYear()} TomTom, Microsoft`,
  subscriptionKey: process.env.REACT_APP_AZURE_MAP_API_KEY, // Add your Azure Maps key to the map SDK. Get an Azure Maps key at https://azure.com/maps. NOTE: The primary key should be used as the key.
  language: 'en-US', // The language of labels. Supported languages: https://docs.microsoft.com/en-us/azure/azure-maps/supported-languages
  view: 'Auto', // The regional view of the map. Supported views: https://aka.ms/AzureMapsLocalizationViews
};

export default function Hook() {
  const basemaps = {
    'Azure Imagery': L.tileLayer(azure_url, { ...azure_map_data, tilesetId: 'microsoft.imagery' }),
    'Azure Base Road': L.tileLayer(azure_url, { ...azure_map_data, tilesetId: 'microsoft.base.road' }),
    'Azure Base Hybrid Road': L.tileLayer(azure_url, { ...azure_map_data, tilesetId: 'microsoft.base.hybrid.road' }),
    'Azure Base Darkgrey': L.tileLayer(azure_url, { ...azure_map_data, tilesetId: 'microsoft.base.darkgrey' }),
  };

  return basemaps;
}
