import { Container, Grid } from '@material-ui/core';
import plus_ahadd from '@Assets/Images/iris_ahadd.svg';
import highway_ahadd from '@Assets/Images/highway_ahadd.svg';
import moment from 'moment';
import React, { Redirect } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '@Context/Auth';
import useHook from './hook';
import UnauthorizedLogin from './UnauthorizedLogin';
import RedirectPage from './Redirect';
import LoginForm from './LoginForm';
import LoginFormMobile from './LoginFormMobile';

export default function AuthPage(props) {
  const h = useHook(props);
  const { token } = useContext(AuthContext);
  const is_login = h.location.pathname.includes('login');
  if (token && h.user !== 'logged out') return <Redirect to={h.from} />;
  return (
    <>
      <UnauthorizedLogin {...props} {...h} />
      <Container maxWidth="false" className="px-0">
        {h.isLogin ? (
          <RedirectPage />
        ) : (
          <div className="d-flex justify-content-between align-items-center" style={{ minHeight: '100vh' }}>
            <div
              className="d-flex justify-content-start align-items-center w-100"
              style={{
                flex: 1,
                height: '100vh',
                backgroundImage: `url(${highway_ahadd})`,
                backgroundSize: 'cover',
              }}
            >
              <div className="d-none d-lg-block w-100">
                <div
                  className="d-flex flex-column w-100 justify-content-center align-items-end"
                  style={{ flex: 1 }}
                >
                  <div className="w-50 d-flex flex-column justify-content-center align-items-center">
                    <img
                      src={plus_ahadd}
                      className="img-fluid"
                      style={{ width: '15vw' }}
                    />
                    <p style={{ color: '#0061AA', fontWeight: 'bold', fontSize: '2.5vh' }}>Automated Highway Asset Defect Detection</p>
                    <p className="text-justify text-dark mt-2" style={{ fontSize: '2vh', width: '25vw' }}>
                      IRIS is a cross-functional collaborative initiative system used for monitoring and inspection of PLUS expressway assets. IRIS is an application born through Project Automated Highway Asset Defect Detection System (AHADD). It has the capability to perform automated defect detection and categorised it into different severity levels.
                    </p>
                    {is_login && <LoginForm {...h} />}
                  </div>
                </div>
              </div>
              <div className="d-flex d-lg-none w-100 justify-content-center align-items-center flex-column" style={{ flex: 1 }}>
                <div className="d-flex flex-column justify-content-center align-items-center p-2 paper-shadow bg-white" style={{ width: '65%' }}>
                  <img
                    src={plus_ahadd}
                    className="img-fluid"
                    style={{ width: '25vw' }}
                  />
                  <p className="text-center" style={{ color: '#0061AA', fontWeight: 'bold', fontSize: '2.5vh' }}>
                    Automated Highway Asset Defect Detection
                  </p>
                  <p className="text-justify text-dark mt-2" style={{ fontSize: '2vh', width: '60vw' }}>
                    IRIS is a cross-functional collaborative initiative system used for monitoring and inspection of PLUS expressway assets. IRIS is an application born through Project Automated Highway Asset Defect Detection System (AHADD). It has the capability to perform automated defect detection and categorised it into different severity levels.
                  </p>
                  {is_login && <LoginFormMobile {...h} />}
                  <p className="d-block d-sm-none text-center text-dark p-2" style={{ fontSize: '1.5vh' }}>
                    Please set mobile view to desktop view for best experience
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </Container>
      <div>
        <Grid container justify="center" alignItems="center" className="footer">
          <p className="my-auto text-dark text-center" style={{ fontSize: 11 }}>
            {moment().year()} AHADD - Automated Highway Asset Defect Detection powered by&nbsp;
            <a href="https://ofo.my" target="_blank" rel="noopener noreferrer">
              OFO Tech
            </a>
          </p>
        </Grid>
      </div>
    </>
  );
}
