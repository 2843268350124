import CenteredLoadingContainer from '@Components/CenteredLoadingContainer';
import MainContentContainer from '@Components/MainContentContainer';
import Table from '@Components/MaterialTable/v11';
import { Grid, IconButton } from '@material-ui/core';
import DataNotFoundImg from '@Assets/Images/Data-not-found3.svg';
import { Refresh } from '@material-ui/icons';
import useHook from './hook';

const titleStyle = {
  fontWeight: 600,
  fontSize: 28,
  color: 'var(--dark-blue-color)',
};

export default function BatchMonitoring() {
  const h = useHook();
  return (
    <MainContentContainer style={{ marginTop: '1rem' }}>
      <div className="d-flex" style={{ justifyContent: 'space-between' }}>
        <h1
          className="my-auto pb-2"
          style={titleStyle}
        >
          Batch Monitoring
        </h1>
        <IconButton className="p-0">
          <Refresh onClick={h.refresh} />
        </IconButton>
      </div>
      <Grid container spacing={1}>
        {h.isLoading ? <CenteredLoadingContainer height="50vh" size={75} hasText text="data" /> : (
          <>
            <Grid container item xs={12} className="d-flex justify-content-center">
              {!!h.batchList?.length ? (
                <Grid item xs={12}>
                  <Table tableData={h.batchList} {...h} />
                </Grid>
              ) : <img src={DataNotFoundImg} style={{ width: '30vw' }} />}
            </Grid>
          </>
        )}
      </Grid>
    </MainContentContainer>
  );
}
