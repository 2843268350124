/* eslint-disable complexity */
import React from 'react';
import MUIDataTable from 'mui-datatables';
import { createMuiTheme, makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import moment from 'moment';

const useStyles = makeStyles({
  centeredHeader: {
    '& span': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
});

export default ({ tableData }) => {
  const classes = useStyles();

  const columns = [
    {
      name: 'ID',
      selector: 'id',
      options: {
        setCellHeaderProps: () => ({ className: classes.centeredHeader }),
        setCellProps: () => ({ align: 'center' }),
      },
    },
    {
      name: 'Name',
      selector: 'name',
      options: {
        setCellHeaderProps: () => ({ className: classes.centeredHeader }),
        setCellProps: () => ({ align: 'center' }),
      },
    },
    {
      name: 'Job Name',
      selector: 'jobName',
      options: {
        setCellHeaderProps: () => ({ className: classes.centeredHeader }),
        setCellProps: () => ({ align: 'center' }),
      },
    },
    {
      name: 'Status',
      selector: 'status',
      options: {
        setCellHeaderProps: () => ({ className: classes.centeredHeader }),
        setCellProps: () => ({ align: 'center' }),
      },
    },
    {
      name: 'Updated At',
      selector: 'updatedAt',
      options: {
        setCellHeaderProps: () => ({ className: classes.centeredHeader }),
        setCellProps: () => ({ align: 'center' }),
      },
    },
  ];

  const options = {
    filter: 'false',
    print: 'false',
    download: 'false',
    sort: 'false',
    viewColumns: 'false',
    search: 'false',
    tableBodyMaxHeight: '65vh',
    onRowsDelete: () => false,
    customToolbarSelect: () => <div />,
    selectableRowsHideCheckboxes: true
  };

  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        options={options}
        columns={columns.map((col) => ({ ...col, label: col.name, name: col.selector }))}
        data={tableData.map((x, idx) => {
          return ({
            ...x,
            updatedAt: moment(x.updatedAt).format('DD/MM/YYYY hh:mm a')
          });
        })}
      />
    </MuiThemeProvider>
  );
};

const getMuiTheme = () => createMuiTheme({
  typography: {
    fontFamily: 'CeraProRegular',
  },
  overrides: {
    MuiTableHead: {
      root: {
        '& .MuiTableHead-root': {
          color: '#000000DE',
          fontSize: 14,
          fontWeight: 500,
          verticalAlign: 'middle',
        },
        '& .MuiTableCell-root': {
          color: '#000000DE',
          fontSize: 14,
          fontWeight: 500,
          verticalAlign: 'middle',
        },
      },
    },
    MUIDataTableHeadCell: {
      toolButton: {
        margin: '0 !important',
      },
    },
  },
});
