import { useEffect, useState } from 'react';
import Api, { endpoints } from '@Helpers/api';
import { capitalizeAllWords } from '@Helpers';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';

const defaultTableArray = [
  { x: 1, y: 0, fill: '#4FD8AF' },
  { x: 2, y: 0, fill: '#008FFB' },
  { x: 3, y: 0, fill: '#8B95AB' },
  { x: 4, y: 0, fill: '#FEB019' },
  { x: 5, y: 0, fill: '#EA1601' },
];

export default function Hook() {
  const location = useLocation();
  const pageCondition = !!location.pathname.split('/')[2] ? location.pathname.split('/')[2] : 'Slope';
  const [asset, setAsset] = useState([]);
  const [filterAsset, setFilterAsset] = useState([]);
  const [networks, setNetworks] = useState([]);
  const [sections, setSections] = useState([]);
  const [regions, setRegions] = useState([]);
  const [rankings, setRankings] = useState([]);
  const [bridgeType, setBridgeType] = useState([]);
  const [slopeCondition, setSlopeCondition] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState([]);
  const [selectedSection, setSelectedSection] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState([]);
  const [selectedRanking, setSelectedRanking] = useState([]);
  const [selectedBridgeType, setSelectedBridgeType] = useState([]);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selected_project, set_selected_project] = useState(0);
  const [project, setProject] = useState({});
  const [slopeRating, setSlopeRating] = useState(defaultTableArray);
  const [overallSlope, setOverallSlope] = useState(defaultTableArray);
  const [title, setTitle] = useState('');
  const [tab, setTab] = useState(0);
  const customFilterByType = pageCondition === 'slope' ? { RankingId: selectedRanking } : { bridge_type: selectedBridgeType }
  const filterType = {
    RegionId: selectedRegion, NetworkId: selectedNetwork, SectionId: selectedSection, ...customFilterByType,
  };

  const refresh = () => {
    setIsLoading(false);
    Api({
      endpoint: endpoints.getAssets(),
      onSuccess: ({ data }) => {
        const dataList = data.filter(e => e.AssetTypeId === (pageCondition === 'slope' ? 1 : 2))
        setAsset(dataList);
        setFilterAsset(dataList);
        setIsLoading(true);
      },
      onFail: (err) => toast('error', err),
    });
  }

  useEffect(() => {
    Api({
      endpoint: endpoints.getStaticData(),
      onSuccess: ({ data }) => {
        setNetworks(data.Network.map(m => ({ label: m.name, value: m.id })));
        setRegions(data.Region.map(m => ({ label: m.name, value: m.id })));
        setSections(data.Section.map(m => ({ label: m.name, value: m.id })));
        setRankings(data.Ranking.map(m => ({ label: m.name, value: m.id })));
        setSlopeCondition(data.SlopeCondition.map(m => ({ label: m.name, value: m.id, ...m })));
        setBridgeType(data.BridgeType.map(m => ({ label: m.name, value: String(m.id) })));
      },
      onFail: (err) => toast('error', err),
    });
  }, []);

  useEffect(() => {
    if (!pageCondition) setTitle('Slope');
    setTitle(capitalizeAllWords(pageCondition.split('-').join(' ')));
    refresh();
  }, [pageCondition])

  const searchKeyword = (array) => {
    if (!keyword) return setFilterAsset(array);
    const result = array?.filter((data) => {
      return data.name?.toLowerCase().search(keyword) != -1;
    });
    setFilterAsset(result);
  };

  useEffect(() => {
    const hasFilterType = (Object.keys(filterType).map(m => filterType[m].length)).filter(f => !!f);
    const selectedFilteredType = [];
    Object.keys(filterType).forEach(m => {
      if (filterType[m].length) selectedFilteredType.push({ [m]: filterType[m] });
    });
    const filteredTypeObj = Object.assign({}, ...selectedFilteredType);
    if (!hasFilterType.length) {
      searchKeyword(asset);
    } else {
      const result = asset.filter(e => {
        // eslint-disable-next-line no-restricted-syntax
        for (const key in filteredTypeObj) {
          if (e[key] === undefined || !filteredTypeObj[key].includes(e[key])) return false;
        }
        return true;
      });
      searchKeyword(_.uniq(result.flat()));
    }
  }, [keyword, selectedNetwork, selectedRegion, selectedSection, selectedRanking, selectedBridgeType]);

  useEffect(() => {
    if (!asset.length) return;
    if (!asset[selected_project]) return;
    setProject(asset[selected_project]);
    setSelectedRow([asset[selected_project]]);
  }, [asset, selected_project]);

  useEffect(() => {
    if (!asset.length) return;
    if (!selectedRow.length) return;
    set_selected_project(asset.indexOf(selectedRow[0]));
  }, [asset, selectedRow]);

  useEffect(() => {
    setSlopeRating(prevVal => prevVal.map(ann => ({ ...ann, y: 0 })));
    setOverallSlope(prevVal => prevVal.map(ann => ({ ...ann, y: 0 })));
    if (!project.id) return;
    const inspectionList = project.aerial_inspections_details;
    if (!inspectionList.length) return;
    inspectionList.forEach(element => {
      const switchValue = { // need to reverse the id to make incresing order
        1: 5,
        2: 4,
        3: 3,
        4: 2,
        5: 1,
      }[element.eng_slope_condition];
      setOverallSlope(prevVal => prevVal.map(ann => (ann.x === switchValue ? ({ ...ann, y: ann.y + 1 }) : ann)));
    });
    inspectionList.forEach(element => {
      if (!element.InspectionFiles.length) return;
      element.InspectionFiles.forEach(element2 => {
        if (!element2.Annotations.length) return;
        element2.Annotations.forEach(element3 => {
          setSlopeRating(prevVal => prevVal.map(ann => (ann.x === element3.SeverityId ? ({ ...ann, y: ann.y + 1 }) : ann)));
        });
      });
    });
  }, [project]);

  const tabList = [
    { label: 'PATROL', selector: 0 },
    { label: 'DRONE', selector: 1 },
  ];

  return {
    asset,
    networks,
    sections,
    regions,
    rankings,
    bridgeType,
    slopeCondition,
    selectedRow,
    setSelectedRow,
    openFilterDialog,
    setOpenFilterDialog,
    selectedNetwork,
    setSelectedNetwork,
    selectedSection,
    setSelectedSection,
    selectedRegion,
    setSelectedRegion,
    selectedRanking,
    setSelectedRanking,
    selectedBridgeType,
    setSelectedBridgeType,
    filterAsset,
    keyword,
    setKeyword,
    isLoading,
    selected_project,
    set_selected_project,
    project,
    slopeRating,
    overallSlope,
    pageCondition,
    title,
    setTitle,
    tabList,
    tab,
    setTab,
  };
}
