import { Button } from 'reactstrap';
import microsoftIcon from '@Assets/Icons/microsoftIcon.svg';

export default function LoginForm() {
  const loginUrl = `${window.location.origin}/login`;
  return (
    <Button
      className="w-50 my-3 p-1"
      style={{ backgroundColor: '#fff', borderRadius: '5px' }}
      onClick={() => window.location.replace(`${process.env.REACT_APP_ENDPOINT_URL}auth/microsoft?redirect_url=${loginUrl}`)}
      children={(
        <div
          className="d-flex align-items-center"
          style={{
            justifyContent: 'space-evenly', color: '#5B6272', fontWeight: 600, fontSize: '2vh',
          }}
        >
          <img src={microsoftIcon} style={{ width: '2vw' }} />
          SIGN IN VIA MICROSOFT
        </div>
      )}
    />
  );
}
