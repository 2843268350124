import CenteredLoadingContainer from '@Components/CenteredLoadingContainer';
import MainContentContainer from '@Components/MainContentContainer';
import Table from '@Components/MaterialTable/v9';
import { Button, Grid, IconButton } from '@material-ui/core';
import DataNotFoundImg from '@Assets/Images/Data-not-found3.svg';
import SortDropdown from '@Components/SortDropdown';
import { GetApp, Refresh } from '@material-ui/icons';
import DeleteDialog from '@Components/DeleteDialog';
import RestoreDialog from '@Components/RestoreDialog';
import useHook from './hook';

const titleStyle = {
  fontWeight: 600,
  fontSize: 28,
  color: 'var(--dark-blue-color)',
};

export default function ReportList() {
  const h = useHook();
  return (
    <MainContentContainer style={{ marginTop: '1rem' }}>
      <div className="d-flex" style={{ justifyContent: 'space-between' }}>
        <h1
          className="my-auto pb-2"
          style={titleStyle}
        >
          {h.showDeleted ? 'Deleted Report' : 'Report List'}
        </h1>
      </div>
      <Grid container spacing={1}>
        {h.isLoading ? <CenteredLoadingContainer height="50vh" size={75} hasText text="reports" /> : (
          <>
            <Grid item xs={12} className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                {[
                  { selected: h.selectedCycleFilter, setSelected: h.setSelectedCycleFilter, data: h.cycle, title: 'Cycle' },
                  { selected: h.selectedYearFilter, setSelected: h.setSelectedYearFilter, data: h.yearList, title: 'Year' },
                  { selected: h.selectedNetworkFilter, setSelected: h.setSelectedNetworkFilter, data: h.networks, title: 'Network' },
                  { selected: h.selectedRegionFilter, setSelected: h.setSelectedRegionFilter, data: h.regions, title: 'Region' },
                  { selected: h.selectedSectionFilter, setSelected: h.setSelectedSectionFilter, data: h.sections, title: 'Section' },
                  { selected: h.selectedRankingFilter, setSelected: h.setSelectedRankingFilter, data: h.rankings, title: 'Slope Hazard & Risk Ranking' },
                ].map(sort => (
                  <SortDropdown {...sort} />
                ))}
                <IconButton className="p-0 pr-3">
                  <Refresh onClick={h.refresh} />
                </IconButton>
                {!!h.selectedRow?.length && (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<GetApp />}
                    style={{ backgroundColor: 'var(--dark-blue-color)' }}
                    onClick={h.downloadMultipleFile}
                  >
                    Download
                  </Button>
                )}
              </div>
              <p
                className="d-flex align-items-center"
                style={{ fontSize: 14, color: '#0691fb', cursor: 'pointer' }}
                onClick={() => h.setShowDeleted(prev => !prev)}
              >
                {h.showDeleted ? (
                  <><span style={{ fontSize: 20 }}>&#129168;&nbsp;</span>Back to Report</>
                ) : (
                  <>Deleted History<span style={{ fontSize: 20 }}>&nbsp;&#129170;</span></>
                )}
              </p>
            </Grid>
            <Grid container item xs={12} className="d-flex justify-content-center">
              {!!h.allReports?.length ? (
                <Grid item xs={12}>
                  <Table tableData={h.filterReports} {...h} />
                </Grid>
              ) : <img src={DataNotFoundImg} style={{ width: '30vw' }} />}
            </Grid>
          </>
        )}
      </Grid>
      <DeleteDialog
        open={h.openDialog}
        setOpen={h.setOpenDialog}
        selected={h.selectedReport}
        setSelected={h.setSelectedReport}
        deleteFunction={() => h.onDelete()}
      />
      <RestoreDialog
        open={h.openRestoreDialog}
        setOpen={h.setOpenRestoreDialog}
        selected={h.selectedReport}
        setSelected={h.setSelectedReport}
        restoreFunction={() => h.onRestoreReport()}
      />
    </MainContentContainer>
  );
}
