import { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import Table from '@Components/MaterialTable/v7';
import Navbar from '@Components/Navbar';
import NoDataImg from '@Assets/Images/Data-not-found3.svg';
import DeleteDialog from '@Components/DeleteDialog';
import MainContentContainer from '@Components/MainContentContainer';
import AvatarIcon from '@Components/AvatarIcon';
import PaginationV2 from '@Components/PaginationV2';
import CenteredLoadingContainer from '@Components/CenteredLoadingContainer';
import UploadDocumentDialog from '@Components/UploadDocumentDialog';
import { AccountCircle, AddOutlined } from '@material-ui/icons';
import { ReportIcon } from '@Assets/Icons';
import Button from '@Components/Button';
import { ActionBtn } from './Components';
import Hook from './hook';

const searchCategory = [
  { key: 'documentNumber', label: 'Document No' },
  { key: 'title', label: 'Document Title' },
];

export default function Document(props) {
  const h = Hook(props);
  const [data, setData] = useState([]);

  const columns = [
    {
      name: 'Document No.',
      selector: 'id',
      options: {
        setCellHeaderProps: () => ({ style: { textAlign: 'left' } }),
        setCellProps: () => ({ style: { textAlign: 'left' } }),
        sort: false,
      },
    },
    {
      name: 'Document Title',
      selector: 'title',
      options: {
        setCellHeaderProps: () => ({ align: 'left' }),
        setCellProps: () => ({ align: 'left' }),
        sort: false,
      },
    },
    {
      name: 'Document Date',
      selector: 'documentDate',
      options: {
        setCellHeaderProps: () => ({ align: 'center' }),
        setCellProps: () => ({ align: 'center' }),
        sort: false,
      },
    },
    {
      name: 'Uploaded Date',
      selector: 'uploadedDate',
      options: {
        setCellHeaderProps: () => ({ align: 'center' }),
        setCellProps: () => ({ align: 'center' }),
        sort: false,
      },
    },
    {
      name: 'Upload By',
      selector: 'uploadedBy',
      options: {
        setCellHeaderProps: () => ({ align: 'center' }),
        setCellProps: () => ({ align: 'center' }),
        sort: false,
      },
    },
    {
      name: 'Actions',
      selector: 'actions',
      options: {
        setCellHeaderProps: () => ({ align: 'center' }),
        setCellProps: () => ({ align: 'center' }),
        sort: false,
      },
    },
  ];

  useEffect(() => {
    const documentData = h.documents?.map((m, idx) => ({
      ...m,
      // id: (h.page - 1) * h.perPage + idx + 1,
      id: m.documentNumber,
      title: <div className="d-flex align-items-center"><ReportIcon transform="scale(0.6)" /> {m.title}</div>,
      documentDate: moment(m.documentDateAt).format('DD MMMM YYYY'),
      uploadedDate: moment(m.createdAt).format('DD MMMM YYYY'),
      uploadedBy: (
        <div className="flex-standard">
          {!!m.User ? <AvatarIcon user={m.User} size="2.5em" /> : <AccountCircle style={{ fontSize: '3em' }} />}
        </div>
      ),
      actions: <ActionBtn document={m} setOpen={h.setOpenDialog} setSelectedDocument={h.setSelectedDocument} />,
    }));
    setData(documentData);
  }, [h.documents]);

  return (
    <MainContentContainer>
      <div style={{
        display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBlock: 20,
      }}
      >
        <Navbar
          to="/project/"
          text="Document"
        />
        <Button
          variant="contained"
          style={{
            color: '#FFFFFF',
            borderRadius: 30,
            background: 'linear-gradient(var(--main-color), var(--primary-color))',
            width: '10rem',
          }}
          onClick={() => h.setOpenUploadDocumentDialog(true)}
        >
          <AddOutlined />
          <p className="text-white ml-2">Upload</p>
        </Button>
      </div>
      {h.isLoading ? <CenteredLoadingContainer height="50vh" size={75} hasText text="Documents" /> : (
        <Grid container>
          <Grid item xs={12}>
            <PaginationV2 keysList={searchCategory} totalData={h.totalData} onRefresh={h.refresh} {...h} />
          </Grid>
          <Grid item xs={12} style={{ padding: 20 }}>
            {data.length ? (
              <Table {...h} columns={columns} tableData={data} />
            ) : (
              <div className="d-flex justify-content-center">
                <img src={NoDataImg} style={{ width: '30vw' }} />
              </div>
            )}
          </Grid>
        </Grid>
      )}
      <DeleteDialog
        open={h.openDialog}
        setOpen={h.setOpenDialog}
        selected={h.selectedDocument}
        setSelected={h.setSelectedDocument}
        deleteFunction={() => h.onDelete()}
      />
      <UploadDocumentDialog
        open={h.openUploadDocumentDialog}
        setOpen={h.setOpenUploadDocumentDialog}
        files={h.files}
        setFiles={h.setFiles}
        uploadFunction={h.onUploadDocument}
        progress={h.uploadPercentages[0]}
        title={h.documentTitle}
        no={h.documentNumber}
        date={h.documentDateAt}
        setTitle={h.setDocumentTitle}
        setNo={h.setDocumentNumber}
        setDate={h.setDocumentDateAt}
      />
    </MainContentContainer>
  );
}
