import { useState } from 'react';
import { todayDateTime } from '@Helpers';
import moment from 'moment';

export default function hook({ project, onSave }) {
  const [open, set_open] = useState(false);
  const [cycle, set_cycle] = useState();
  const [date, set_date] = useState(todayDateTime);
  const [email, setEmail] = useState('');

  const handleSubmit = () => {
    if (!cycle) return;
    const input = {
      cycle,
      inspectionDateAt: date,
      year: moment(date).format('YYYY'),
      email,
      AssetId: project.id,
    };
    onSave(input);
    set_open(false);
    resetForm();
  };

  const resetForm = () => {
    set_date(todayDateTime);
    set_cycle();
    setEmail('');
  };
  const handleClose = () => { set_open(false); resetForm(); };
  return {
    open,
    set_open,
    cycle,
    set_cycle,
    date,
    set_date,
    handleSubmit,
    resetForm,
    handleClose,
    email,
    setEmail,
  };
}
