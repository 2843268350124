import {
  VictoryAxis, VictoryBar, VictoryChart, VictoryGroup, VictoryLegend, VictoryTheme, VictoryTooltip,
} from 'victory';

export default function GroupBar({ chartData, slopeCondition }) {
  const switchValue = (value, type = 'eng') => {
    if (type === 'eng') {
      return { // need to reverse the id to make incresing order
        1: 5, 2: 4, 3: 3, 4: 2, 5: 1,
      }[value];
    }
    return { // need to reverse the id to make incresing order
      0.2: 1, 0.4: 0.8, 0.6: 0.6, 0.8: 0.4, 1: 0.2,
    }[value];
  };

  const sortData = chartData.aerial_inspections_details.sort((a, b) => b.date.localeCompare(a.date)).slice(0, 4).sort((a, b) => a.date.localeCompare(b.date));
  const tableData = sortData.map(e => ({
    x: `${[4].includes(e.cycle) ? 'Others' : `Cycle ${e.cycle}`} (${e.year})`,
    y1: switchValue(e.eng_slope_condition, 'eng') ?? 0,
    y2: Math.round((switchValue(e?.ai_slope_condition, 'ai') ?? 0) * 5),
  }));
  const slopeConditionAsset = slopeCondition.filter(e => e.AssetTypeId === chartData.AssetTypeId);
  return (
    <VictoryChart
      height={300}
      width={600}
      padding={{
        top: 20, bottom: 100, right: 150, left: 20,
      }}
      theme={VictoryTheme.material}
    >
      <VictoryAxis
        tickFormat={(x) => x}
        style={{
          tickLabels: { fontFamily: 'CeraProRegular', padding: 8 },
          ticks: { size: 0 },
          axis: { stroke: 'rgba(30, 52, 101, 0.15)' },
          grid: { stroke: 'transparent' },
        }}
      />
      <VictoryAxis
        dependentAxis
        crossAxis
        theme={VictoryTheme.material}
        standalone={false}
        tickFormat={(t) => `${slopeCondition.filter(e => e.AssetTypeId === chartData.AssetTypeId).find(e => e.name === t)?.label}`}
        tickValues={['Very Poor', 'Poor', 'Average', 'Good', 'Very Good']}
        style={{
          ticks: { size: 0 },
          grid: { stroke: 'transparent' },
        }}
      />
      <VictoryGroup offset={25} colorScale={['#008FFB', '#4FD8AF']}>
        {[
          {
            y: 'y1', duration: 2000, labels: ({ datum }) => datum.y1,
          },
          {
            y: 'y2', duration: 2000, labels: ({ datum }) => datum.y2,
          },
        ].map(e => (
          <VictoryBar
            data={tableData}
            x="x"
            y={e.y}
            barWidth={25}
            labelComponent={<VictoryTooltip />}
            labels={({ datum }) => {
              return `${datum._stack === 1 ? 'Engineer' : 'AI'} Evaluation
              Slope Condition: ${slopeConditionAsset.find(f => f.id === datum._y)?.label}`;
            }}
            animate={{
              duration: 2000,
              onLoad: { duration: e.duration },
            }}
          />
        ))}
      </VictoryGroup>
      <VictoryLegend
        x={500}
        y={40}
        orientation="horizontal"
        standalone={false}
        itemsPerRow={1}
        gutter={10}
        data={[
          { name: 'Engineer\nEvaluation', symbol: { fill: '#008FFB', type: 'square' } },
          { name: 'AI Evaluation', symbol: { fill: '#4FD8AF', type: 'square' } },
        ]}
        style={{ labels: { fontFamily: 'CeraProRegular', fontSize: 15 } }}
      />
    </VictoryChart>
  );
}
