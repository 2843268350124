/* eslint-disable max-lines */
/* eslint-disable max-depth */
import { useEffect, useState } from 'react';
import Api, { endpoints } from '@Helpers/api';
import { getKeyByValue } from '@Helpers';
import moment from 'moment';
import _ from 'lodash';


export default function Hook() {
  const pageCondition = window.location.href.split('/')[4];
  // to be pull from static db
  const staticRank = [
    { selector: 1, name: 'Rx5' },
    { selector: 2, name: 'Rx4' },
    { selector: 3, name: 'Rx3' },
    { selector: 4, name: 'Rx2' },
    { selector: 5, name: 'Rx1' },
    { selector: 6, name: 'Unrank' },
  ];

  const staticCondition = [
    { selector: 1, name: 'Very Good' },
    { selector: 2, name: 'Good' },
    { selector: 3, name: 'Average' },
    { selector: 4, name: 'Poor' },
    { selector: 5, name: 'Very Poor' },
  ];

  // to be pull from static db
  const [openContractorDialog, setOpenContractorDialog] = useState(false);
  const [years, setYears] = useState([...Array(20)].map((xx, idx) => moment().year() - idx));
  const [cycles, setCycles] = useState([...Array(4)].map((xx, idx) => idx + 1));

  const [byNetworks, setByNetworks] = useState([]);
  const [bySections, setBySections] = useState([]);
  const [networkList, setNetworkList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [byRanks, setByRanks] = useState([]);
  const [bySlopeConditions, setBySlopeConditions] = useState([]);
  const [dataSource, setDataSource] = useState([]);

  const [tab, setTab] = useState(0);
  const [tags, setTags] = useState([]);
  const [tagsFilter, setTagsFilter] = useState(['0']);
  const [isLoading, setIsLoading] = useState(false);

  const [yearIdx, setYearIdx] = useState(0);
  const [cycleIdx, setCycleIdx] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [barData, setBarData] = useState([]);
  const [staticType, setStaticType] = useState([]);

  const getAnalytics = () => {
    if (!years[yearIdx]) return;
    if (!cycles[cycleIdx]) return;
    setIsLoading(true);

    Api({
      endpoint: endpoints.getAnalytics(),
      data: { YEAR: years[yearIdx], CYCLE: cycles[cycleIdx] },
      onSuccess: ({ data, ...rest }) => {
        setByNetworks(data.network);
        setBySections(data.section);
        setByRanks(data.rankings);
        onTabChange(data.network);
        setNetworkList(rest.networks);
        setSectionList(rest.sections);
        setIsLoading(false);
      },
      onFail: (err) => { toast('error', err); setIsLoading(false); },
    });
  };
  const onChangeDataSource = (ds) => {
    if (!ds.length) return;

    // tableData
    const t2Data = {};
    let tData = [];
    if (tab == 2 && pageCondition === 'slope') {
      const spreadData = {};
      for (let spIdx = 0; spIdx < ds.length; spIdx++) {
        const element = ds[spIdx];
        spreadData[element.id] = element.value; // need to use other attribute than id later when bridge come in
      }
      tData.push({
        ...spreadData,
      });
      t2Data.network = networkList.map(eachS => {
        const spreadCData = {};
        for (let spIdx = 0; spIdx < ds.length; spIdx++) {
          const element = ds[spIdx];
          spreadCData[element.id] = element.assets.filter(asset => asset.NetworkId == eachS.id).length;
        }
        return {
          col_idx: eachS.label,
          ...spreadCData,
          // t: eachS.total,
          t: Object.values(spreadCData).reduce((a, b) => a + b, 0),
        };
      });
      t2Data.section = sectionList.map(eachS => {
        const spreadCData = {};
        for (let spIdx = 0; spIdx < ds.length; spIdx++) {
          const element = ds[spIdx];
          spreadCData[element.id] = element.assets?.filter(asset => asset.SectionId == eachS.id).length;
        }
        return {
          col_idx: eachS.name,
          ...spreadCData,
          // t: eachS.total,
          t: Object.values(spreadCData).reduce((a, b) => a + b, 0),
        };
      });
      setTableData(t2Data);
    } else {
      tData = ds.map(eachS => {
        const spreadData = {};
        for (let spIdx = 0; spIdx < eachS.slopeConditions.length; spIdx++) {
          const element = eachS.slopeConditions[spIdx];
          spreadData[element.id] = element.value; // need to use other attribute than id later when bridge come in
        }
        return {
          col_idx: eachS.label,
          ...spreadData,
          // t: eachS.total,
          t: Object.values(spreadData).reduce((a, b) => a + b, 0),
        };
      });
      setTableData(tData);
    }
    setBarData(tData);
    // set bar data
    // let eachSlopeConditionCData = [];
    // for (let dsIdx = 0; dsIdx < ds.length; dsIdx++) {
    //   const eachS = ds[dsIdx];
    //   for (let slopeConditionIdx = 0; slopeConditionIdx < eachS.slopeConditions.length; slopeConditionIdx++) {
    //     const element = eachS.slopeConditions[slopeConditionIdx];
    //     const xIdx = element.id;
    //     const hasData = eachSlopeConditionCData.find(s => s.xIdx == xIdx);
    //     if (!hasData) {
    //       eachSlopeConditionCData.push({
    //         xIdx,
    //         x: staticRank.find(s => s.selector == xIdx).name,
    //         ann: element.details,
    //       });
    //     } else {
    //       hasData.ann = [...hasData.ann, ...element.details];
    //     }
    //   }
    // }
    // const keys = {};
    // for (let ConIdx = 0; ConIdx < staticCondition.length; ConIdx++) {
    //   const { selector } = staticCondition[ConIdx];
    //   keys[selector] = 0;
    // }
    // eachRankCData = eachRankCData
    //   .filter(sData => !!sData.ann.length)
    //   .map(sData => {
    //     // by annotation
    //     const ann = { ...keys, ..._.countBy(sData.ann, 'SeverityId') };
    //     // END: by annotation
    //     // by slope only
    //     const slopeByAsset = _.chain(sData.ann)
    //       .groupBy('InspectionFile.Inspection.AssetId')
    //       .map((asset_ann, AssetId) => {
    //         const countOfSeverity = _.countBy(asset_ann, 'SeverityId');
    //         // get mean severity
    //         const SeverityId = Number(getKeyByValue(countOfSeverity, _.max(Object.values(countOfSeverity))));
    //         return { AssetId, SeverityId }
    //       })
    //       .value();
    //     const slope = { ...keys, ..._.countBy(slopeByAsset, 'SeverityId') };
    //     // END: by slope only
    //     return { ...sData, ann, slope };
    //   });
    // setBarData(tData);
  };
  const onTabChange = (d) => {
    let data = [];
    switch (tab) {
      case 1:
        data = bySections;
        break;
      case 2:
        data = byRanks;
        break;
      default:
        data = byNetworks;
        break;
    }
    if (d) data = d;
    if (pageCondition === 'bridge' && tab === 2) data = bySections;
    setDataSource(data);
    setTags(tab === 2 && pageCondition === 'slope' ? [] : data.map(eachS => ({ label: eachS.label, value: eachS.id })));
    setTagsFilter(['0']);
  };

  const getStaticData = () => {
    Api({
      endpoint: endpoints.getStaticData(),
      onSuccess: ({ data }) => {
        setStaticType(data.BridgeType.map(d => ({ selector: d.id, ...d })));
      },
      onFail: (err) => { toast('error', err); setIsLoading(false); },
    });
  };

  useEffect(() => {
    getAnalytics();
    getStaticData();
  }, []);
  useEffect(() => {
    getAnalytics();
  }, [yearIdx, cycleIdx]);
  useEffect(() => {
    let data = [];
    data = dataSource;
    if (!tagsFilter.includes('0')) data = data.filter(eachS => tagsFilter.includes(String(eachS.id)));
    onChangeDataSource(data);
  }, [tagsFilter]);
  useEffect(() => {
    onTabChange();
  }, [tab]);
  useEffect(() => {
    setTab(0);
  }, [pageCondition]);

  return {
    staticRank,
    staticCondition,
    openContractorDialog,
    setOpenContractorDialog,
    isLoading,
    tab,
    setTab,
    tags,
    tagsFilter,
    setTagsFilter,
    yearIdx,
    setYearIdx,
    cycleIdx,
    setCycleIdx,
    years,
    cycles,
    staticType,
    byNetworks,
    bySections,
    byRanks,
    tableData,
    barData,
    pageCondition,
  };
}
