import Map from '@Components/MapV2';
import { Grid } from '@material-ui/core';
import CenteredLoadingContainer from '@Components/CenteredLoadingContainer';
import BarChart from '@Components/BarChart/v5';
import useHook from './hook';
import AssetTable from './AssetTable';
import GroupBar from './GroupBar';
import GradientTabs from '@Components/GradientTabs';
import GroupBarChart from '@Components/BarChart/GroupBarChart';
import moment from 'moment';

const titleStyle = {
  fontWeight: 600,
  fontSize: 28,
  color: 'var(--dark-blue-color)',
};

export default function Dashboard() {
  const h = useHook();

  const DashboardGraphCard = ({ gridSize, title, component }) => {
    return (
      <Grid item xs={gridSize.xs} md={gridSize.md ?? gridSize.xs} style={{ height: '30vh' }}>
        <div className="paper-shadow bg-white h-100 rounded">
          <div className="p-3" style={{ backgroundColor: 'var(--primary-color)' }}>
            <h4 style={{ color: 'white', fontSize: 16 }}>{title}</h4>
          </div>
          {(!h.project?.aerial_inspections_details.length || !h.filterAsset.length) ? (
            <div className="w-100 h-75 flex-standard">
              No Inspection Record
            </div>
          ) : component}
        </div>
      </Grid>
    );
  };

  return (
    !h.isLoading ? (
      <CenteredLoadingContainer height="70vh" size={75} hasText text="dashboard" />
    ) : (
      <>
        <h1
          className="my-auto pb-3"
          style={titleStyle}
        >
          {`Dashboard - ${h.title}`}
        </h1>
        <Grid container spacing={3}>
          {['pavement'].includes(h.pageCondition) && (
            <Grid xs={12} item>
              <GradientTabs tabs={h.tabList} tab={h.tab} setTab={h.setTab} />
            </Grid>
          )}
          <Grid container item direction="row" xs={9} alignItems="stretch" spacing={2}>
            <Grid item xs={12} style={{ height: '60vh' }}>
              <div className="paper-shadow bg-white rounded h-100">
                <Map mapStyle={{ width: '100%', height: '100%' }} filtered_projects={h.asset} {...h} />
              </div>
            </Grid>
            {[
              {
                gridSize: { xs: 3, md: 3 },
                title: h.title === 'Slope' ? 'Defect Rating' : `${h.title} Condition (AI)`,
                page: ['slope', 'bridge'],
                component: <BarChart label={{ x: 'Count', y: 'Rating' }} category={{ x: ['1', '2', '3', '4', '5'] }} tableData={h.slopeRating} />,
              },
              {
                gridSize: { xs: 3, md: 3 },
                title: `${h.title} Condition Rating`,
                page: ['slope', 'bridge'],
                component: (
                  <BarChart
                    label={{ x: 'Count', y: 'Slope Rank' }}
                    category={{ x: ['Very Poor', 'Poor', 'Average', 'Good', 'Very Good'] }}
                    tableData={h.overallSlope}
                  />
                ),
              },
              {
                gridSize: { xs: 6, md: 6 },
                title: `${h.title} Condition (Graph)`,
                page: ['slope', 'bridge'],
                component: <GroupBar chartData={h.project} slopeCondition={h.slopeCondition} />,
              },
              {
                gridSize: { xs: ['traffic-safety'].includes(h.pageCondition) ? 6 : 3 },
                title: 'Today Detection Overview',
                page: ['pavement', 'traffic-safety'],
                component: <GroupBarChart height={400} width={400} offset={26} data={dataDaily} legendX={120} />,
              },
              {
                gridSize: { xs: 4 },
                title: 'Weekly Detection Overview',
                page: ['pavement'],
                component: <GroupBarChart height={400} width={600} offset={17} data={dataWeek} legendX={300} withDate />,
              },
              {
                gridSize: { xs: ['traffic-safety'].includes(h.pageCondition) ? 6 : 5 },
                title: 'Monthly Detection Overview',
                page: ['pavement', 'traffic-safety'],
                component: <GroupBarChart height={400} width={800} offset={15} data={dataMonth} legendX={500} />,
              },
            ].filter(({ page }) => page.includes(h.pageCondition)).map(e => <DashboardGraphCard {...e} />)}
          </Grid>
          <AssetTable {...h} />
        </Grid>
      </>
    )
  );
}

const dataMonth = [
  {
    type: 'North Bound',
    data: [
      { x: moment().startOf('year').add(0, 'months').format('MMM'), y: 1 },
      { x: moment().startOf('year').add(1, 'months').format('MMM'), y: 2 },
      { x: moment().startOf('year').add(2, 'months').format('MMM'), y: 3 },
      { x: moment().startOf('year').add(3, 'months').format('MMM'), y: 3 },
      { x: moment().startOf('year').add(4, 'months').format('MMM'), y: 3 },
      { x: moment().startOf('year').add(5, 'months').format('MMM'), y: 3 },
      { x: moment().startOf('year').add(6, 'months').format('MMM'), y: 3 },
      { x: moment().startOf('year').add(7, 'months').format('MMM'), y: 3 },
      { x: moment().startOf('year').add(8, 'months').format('MMM'), y: 3 },
      { x: moment().startOf('year').add(9, 'months').format('MMM'), y: 3 },
      { x: moment().startOf('year').add(10, 'months').format('MMM'), y: 3 },
      { x: moment().startOf('year').add(11, 'months').format('MMM'), y: 3 },
    ],
  },
  {
    type: 'South Bound',
    data: [
      { x: moment().startOf('year').add(0, 'months').format('MMM'), y: 2 },
      { x: moment().startOf('year').add(1, 'months').format('MMM'), y: 3 },
      { x: moment().startOf('year').add(2, 'months').format('MMM'), y: 4 },
      { x: moment().startOf('year').add(3, 'months').format('MMM'), y: 4 },
      { x: moment().startOf('year').add(4, 'months').format('MMM'), y: 4 },
      { x: moment().startOf('year').add(5, 'months').format('MMM'), y: 4 },
      { x: moment().startOf('year').add(6, 'months').format('MMM'), y: 4 },
      { x: moment().startOf('year').add(7, 'months').format('MMM'), y: 4 },
      { x: moment().startOf('year').add(8, 'months').format('MMM'), y: 4 },
      { x: moment().startOf('year').add(9, 'months').format('MMM'), y: 4 },
      { x: moment().startOf('year').add(10, 'months').format('MMM'), y: 4 },
      { x: moment().startOf('year').add(11, 'months').format('MMM'), y: 4 },
    ],
  },
];
const dataWeek = [
  {
    type: 'North Bound',
    data: [
      { x: moment().startOf('isoWeek').add(0, 'days').format('ddd-(D/M)'), y: 1 },
      { x: moment().startOf('isoWeek').add(1, 'days').format('ddd-(D/M)'), y: 2 },
      { x: moment().startOf('isoWeek').add(2, 'days').format('ddd-(D/M)'), y: 3 },
      { x: moment().startOf('isoWeek').add(3, 'days').format('ddd-(D/M)'), y: 3 },
      { x: moment().startOf('isoWeek').add(4, 'days').format('ddd-(D/M)'), y: 3 },
      { x: moment().startOf('isoWeek').add(5, 'days').format('ddd-(D/M)'), y: 3 },
      { x: moment().startOf('isoWeek').add(6, 'days').format('ddd-(D/M)'), y: 3 },
    ],
  },
  {
    type: 'South Bound',
    data: [
      { x: moment().startOf('isoWeek').add(0, 'days').format('ddd-(D/M)'), y: 2 },
      { x: moment().startOf('isoWeek').add(1, 'days').format('ddd-(D/M)'), y: 3 },
      { x: moment().startOf('isoWeek').add(2, 'days').format('ddd-(D/M)'), y: 4 },
      { x: moment().startOf('isoWeek').add(3, 'days').format('ddd-(D/M)'), y: 4 },
      { x: moment().startOf('isoWeek').add(4, 'days').format('ddd-(D/M)'), y: 4 },
      { x: moment().startOf('isoWeek').add(5, 'days').format('ddd-(D/M)'), y: 4 },
      { x: moment().startOf('isoWeek').add(6, 'days').format('ddd-(D/M)'), y: 4 },
    ],
  },
];
const dataDaily = [
  {
    type: 'North Bound',
    data: [
      { x: 'Pothole', y: 1 },
      { x: 'Surface Crack', y: 2 },
      { x: 'Road Patch', y: 3 },
    ],
  },
  {
    type: 'South Bound',
    data: [
      { x: 'Pothole', y: 2 },
      { x: 'Surface Crack', y: 3 },
      { x: 'Road Patch', y: 4 },
    ],
  },
];
