/* eslint-disable complexity */
import { useEffect, useState } from 'react';
import Api, { endpoints } from '@Helpers/api';
import queryString from 'query-string';

export default function Hook({ project }) {
  const project_id = queryString.parse(window.location.search).id;
  const [modellingFiles, setModellingFiles] = useState([]);
  const [justUpdated, setJustUpdated] = useState(0);
  const [keys, setKeys] = useState('category');
  const [keyword, setKeyword] = useState('');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedModellingFile, setSelectedModellingFile] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [name, setName] = useState('');
  const [path, setPath] = useState('');
  const [modellingCategoryId, setModellingCategoryId] = useState(1);
  const [modellingCategories, setModellingCategories] = useState([]);

  const refresh = () => {
    if (!project?.id || !project_id) return;
    setIsLoading(true);
    const data = { paginate: true };
    data.page = page;
    data.perpage = perPage;
    data.sortby = 'createdAt,DESC';
    data.filter = `AssetId,${project?.id ?? project_id}${(!!keys && keyword) && `,${keys},${keyword}`}`;
    Api({
      endpoint: endpoints.getModellingFile(),
      data,
      onSuccess: (response) => {
        setModellingFiles(response.data);
        setTotalData(response.total ?? 0);
        setIsLoading(false);
      },
      onFail: () => {
        setModellingFiles([]);
        setIsLoading(false);
      },
    });
  };

  const staticData = () => {
    Api({
      endpoint: endpoints.getStaticData(),
      onSuccess: ({ data: { ModellingCategory } }) => setModellingCategories(ModellingCategory),
      onFail: () => setModellingCategories([]),
    });
  };

  useEffect(() => {
    refresh();
  }, [project, justUpdated, perPage, page]);

  const onDelete = () => {
    setIsLoading(true);
    Api({
      endpoint: endpoints.deleteModellingFile(selectedModellingFile.id),
      onSuccess: () => {
        toast('success', 'Successfully Delete ');
        setJustUpdated(prev => prev + 1);
        setPage(prev => {
          if (!!(modellingFiles.length - 1)) return prev;
          return (!!(prev - 1) ? prev - 1 : 1);
        });
        setSelectedModellingFile({});
      },
      onFail: () => {
        setIsLoading(false);
        toast('error', 'Failed to Delete ');
      },
    });
  };

  const onKeyDown = ({ key }) => {
    if (key === 'Enter') {
      setPage(1);
      refresh();
    }
  };

  useEffect(() => {
    staticData();
    setPage(1);
  }, [perPage]);

  const onUploadModellingFile = () => {
    if (!name) return;
    if (!modellingCategoryId) return;
    if (!path) return;
    const data = {
      name,
      ModellingCategoryId: modellingCategoryId,
      path,
    };
    Api({
      endpoint: endpoints.createModellingFile(project_id),
      data,
      onSuccess: () => {
        toast('success', 'Successfully upload modellingFiles');
        setName('');
        setModellingCategoryId();
        setPath('');
        setOpenUploadDialog(false);
        refresh();
      },
      onFail: () => {
        toast('error', 'Opss, something went wrong, please try again.');
      },
    });
  };

  return {
    modellingFiles,
    onDelete,
    keys,
    setKeys,
    keyword,
    setKeyword,
    page,
    setPage,
    perPage,
    setPerPage,
    onKeyDown,
    totalData,
    isLoading,
    selectedModellingFile,
    setSelectedModellingFile,
    openDialog,
    setOpenDialog,
    refresh,
    onUploadModellingFile,
    openUploadDialog,
    setOpenUploadDialog,
    name,
    setName,
    modellingCategoryId,
    setModellingCategoryId,
    path,
    setPath,
    modellingCategories,
  };
}
