/* eslint-disable guard-for-in */
import { useEffect, useState } from 'react';
import Api, { endpoints } from '@Helpers/api';
import _ from 'lodash';

export default function Hook() {
  const [batchList, setBatchList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    setIsLoading(true);
    Api({
      endpoint: endpoints.getProcessBatch(),
      onSuccess: ({ data }) => {
        setBatchList(data);
        setIsLoading(false);
      },
      onFail: (err) => { toast('error', err); },
    });
  }
  return {
    batchList,
    isLoading,
    refresh,
  };
}
