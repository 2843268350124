/* eslint-disable complexity */
import { useEffect, useState } from 'react';
import Api, { endpoints } from '@Helpers/api';
import queryString from 'query-string';

export default function Hook({ project }) {
  const project_id = queryString.parse(window.location.search).id;
  const [documents, setDocuments] = useState([]);
  const [justUpdated, setJustUpdated] = useState(0);
  const [keys, setKeys] = useState('documentNumber');
  const [keyword, setKeyword] = useState('');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [openUploadDocumentDialog, setOpenUploadDocumentDialog] = useState(false);
  const [files, setFiles] = useState([]);
  const [uploadPercentages, setUploadPercentages] = useState([0]);
  const [documentTitle, setDocumentTitle] = useState('');
  const [documentNumber, setDocumentNumber] = useState('');
  const [documentDateAt, setDocumentDateAt] = useState('');
  const [uploadFiles, setUploadFiles] = useState([]);

  const refresh = () => {
    if (!project?.id || !project_id) return;
    setIsLoading(true);
    const data = { paginate: true };
    data.page = page;
    data.perpage = perPage;
    data.sortby = 'createdAt,DESC';
    data.filter = `AssetId,${project?.id ?? project_id}${(!!keys && keyword) && `,${keys},${keyword}`}`;
    Api({
      endpoint: endpoints.getDocuments(),
      data,
      onSuccess: (response) => {
        setDocuments(response.data);
        setTotalData(response.total ?? 0);
        setIsLoading(false);
      },
      onFail: () => {
        setDocuments([]);
        setIsLoading(false);
      },
    });
  };

  useEffect(() => {
    refresh();
  }, [project, justUpdated, perPage, page]);

  const onDelete = () => {
    setIsLoading(true);
    Api({
      endpoint: endpoints.deleteDocument(selectedDocument.id),
      onSuccess: () => {
        toast('success', 'Successfully Delete ');
        setJustUpdated(prev => prev + 1);
        setPage(prev => {
          if (!!(documents.length - 1)) return prev;
          return (!!(prev - 1) ? prev - 1 : 1);
        });
        setSelectedDocument({});
      },
      onFail: () => {
        setIsLoading(false);
        toast('error', 'Failed to Delete ');
      },
    });
  };

  const onKeyDown = ({ key }) => {
    if (key === 'Enter') {
      setPage(1);
      refresh();
    }
  };

  const updatePercentage = (i, p) => {
    if (p === 100) p = 99;
    if (p === 'done') p = 100;
    setUploadPercentages(arr => { arr[i] = p; return [...arr]; });
  };

  useEffect(() => {
    setPage(1);
  }, [perPage]);

  useEffect(() => {
    if (!files.length) return;
    const isUploadCompleted = !uploadPercentages.some(e => e !== 100);
    if (isUploadCompleted) {
      setFiles([]);
      setUploadPercentages([0]);
      setOpenUploadDocumentDialog(false);
      refresh();
    }
  }, [uploadPercentages]);

  const onUploadDocument = () => {
    if (!files.length) return;
    if (!documentNumber) return;
    if (!documentTitle) return;
    if (!documentDateAt) return;
    const data = {
      documentNumber,
      title: documentTitle,
      documentDateAt,
    };
    setUploadPercentages([...Array(files.length)].map(() => 0));
    files.forEach((file, idx) => {
      const xhr = Api({
        endpoint: endpoints.createDocument(project_id),
        data,
        files: [file],
        uploadPercent: (p) => updatePercentage(idx, p),
        onSuccess: () => {
          toast('success', 'Successfully upload documents');
          updatePercentage(idx, 'done');
          setDocumentTitle('');
          setDocumentNumber('');
          setDocumentDateAt('');
        },
        onFail: () => {
          toast('error', 'Opss, something went wrong, please try again.');
        },
      });
      setUploadFiles(prev => [...prev, xhr]);
    });
  };

  return {
    documents,
    onDelete,
    keys,
    setKeys,
    keyword,
    setKeyword,
    page,
    setPage,
    perPage,
    setPerPage,
    onKeyDown,
    totalData,
    isLoading,
    selectedDocument,
    setSelectedDocument,
    openDialog,
    setOpenDialog,
    refresh,
    onUploadDocument,
    openUploadDocumentDialog,
    setOpenUploadDocumentDialog,
    files,
    setFiles,
    documentTitle,
    setDocumentTitle,
    documentNumber,
    setDocumentNumber,
    documentDateAt,
    setDocumentDateAt,
    uploadFiles,
    setUploadFiles,
    uploadPercentages,
  };
}
