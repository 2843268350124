import React from 'react';
import {
  Button, Grid, Tab, Tabs,
} from '@material-ui/core';
import { PlayCircleFilled } from '@material-ui/icons';
import TabPanel from '@Components/TabPanel/v1';
import { a11yProps } from '@Helpers';
import ReactPlayer from 'react-player';
import './inspection.css';
import DeleteDialog from '@Components/DeleteDialog';
import UploadInspectionPhoto from './UploadInspectionPhoto';
import PinLocation from './PinLocation';

export default function SideBar(props) {
  const tabsItems = [
    { label: 'Image', length: props.images?.length ?? 0, a11yProps: a11yProps(0), action: () => props.setTab(0) },
  ];
  // if (props.inspectionType === 'image') {
  //   tabsItems.push({ label: 'Video', length: props.videos?.length ?? 0, a11yProps: a11yProps(1), action: () => props.setTab(1) })
  // }
  return (
    <div className="paper shadow h-100" style={{ minWidth: '71vw', maxWidth: '100vw' }}>
      <Grid container>
        <Grid item xs={3}>
          <Tabs value={props.tab} onChange={(event, newValue) => props.setTab(newValue)} style={{ paddingLeft: 10 }}>
            {
              tabsItems.map((m, index) => (
                <Tab
                  label={m.label}
                  {...m.a11yProps}
                  style={{ minWidth: '115px' }}
                  component={() => (
                    <Button className="flex-standard px-4 py-2" style={{ width: '6rem' }} onClick={m.action}>
                      <p
                        className={`${'color-secondary'}`}
                        style={{ opacity: 0.9, fontWeight: props.tab == index && 650 }}
                      >
                        {m.label}&nbsp;
                      </p>
                      <div
                        className="bg-color-primary text-white flex-standard px-1"
                        style={{
                          width: 'auto', height: 15, borderRadius: 15, borderWidth: 0, fontSize: 10,
                        }}
                      >
                        {m.length}
                      </div>
                    </Button>
                  )}
                />
              ))
            }
          </Tabs>
        </Grid>
        <Grid item xs={9} className="d-flex align-items-center justify-content-end">
          {!props.mainImage.is_main && <PinLocation {...props} onSubmit={props.pinLocationOnMainImage} />}
          <UploadInspectionPhoto {...props} onSave={props.uploadPhoto} onRefresh={props.refresh} />
        </Grid>
      </Grid>
      <div className="sidebar-container">
        <TabPanel value={props.tab} index={0}>
          <Grid container>
            <div className="wrapper-horizontal">
              {props.images.length > 0
                && props.images.map((image) => {
                  const isselected = image.id == props.mainImage?.id;
                  return (
                    <div className="mx-2">
                      <div
                        className={`d-flex justify-content-end img-container ${isselected ? 'inner-border-inspection' : ''}`}
                        style={{ backgroundImage: `url(${process.env.REACT_APP_FD_BLOB}/${image.thumbnail ?? image.path})` }}
                        onClick={() => props.handleChangeMainImage(image.id)}
                      >
                        {!image.is_main && (
                          <h3
                            className="text-white mr-1 h-25"
                            style={{ fontSize: 16 }}
                            onClick={() => {
                              props.setSelectedItem(props.images.find(d => d.id === image.id));
                              props.setOpenDeleteDialog(true);
                            }}
                          >
                            • • •
                          </h3>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          </Grid>
        </TabPanel>
        <TabPanel value={props.tab} index={1}>
          <Grid container className="my-1 mx-2">
            {props.videos.length > 0
              && props.videos.map((video) => {
                const isselected = video.id == props.mainVideo?.id;
                return (
                  <div
                    className="video-container wrapper-horizontal my-0 mx-1 h-100 pointer"
                    style={isselected ? { border: '2px solid var(--primary-color)', borderRadius: 7 } : {}}
                    onClick={() => props.setMainVideo(video)}
                  >
                    <Grid
                      item
                      container
                      justify="center"
                      alignItems="center"
                      className="video-img bg-secondary"
                      style={{
                        backgroundImage: `url(${process.env.REACT_APP_FD_BLOB}/${video.imgSrc})`,
                        borderRadius: 5,
                      }}
                    >
                      <ReactPlayer
                        url={`${process.env.REACT_APP_FD_BLOB}/${video?.vidSrc}`}
                        width="120px"
                        height="90px"
                      />
                      <PlayCircleFilled className="position-absolute" style={{ fontSize: 50 }} />
                    </Grid>
                    <h3
                      className="text-white float-right position-absolute"
                      style={{ fontSize: 16, marginLeft: 85 }}
                      onClick={() => {
                        props.setSelectedItem(props.videos.find(d => d.id === video.id));
                        props.setOpenDeleteDialog(true);
                      }}
                    >
                      • • •
                    </h3>
                  </div>
                );
              })}
          </Grid>
        </TabPanel>
      </div>
      <DeleteDialog
        open={props.openDeleteDialog}
        setOpen={props.setOpenDeleteDialog}
        selected={props.selectedItem}
        setSelected={props.setSelectedItem}
        deleteFunction={props.deleteImage}
        showImage={!props.selectedItem?.isVideo}
      />
    </div>
  );
}
