/* eslint-disable max-lines */
import { useState, useEffect } from 'react';
import {
  Grid, IconButton, makeStyles, Tooltip, Button,
} from '@material-ui/core';
import moment from 'moment';
import Table from '@Components/MaterialTable/v7';
import Navbar from '@Components/Navbar';
import NoDataImg from '@Assets/Images/Data-not-found3.svg';
import DeleteDialog from '@Components/DeleteDialog';
import MainContentContainer from '@Components/MainContentContainer';
import AvatarIcon from '@Components/AvatarIcon';
import PaginationV2 from '@Components/PaginationV2';
import CenteredLoadingContainer from '@Components/CenteredLoadingContainer';
import { AccountCircle } from '@material-ui/icons';
import restoreIcon from '@Assets/Icons/restore_icon.svg';
import RestoreDialog from '@Components/RestoreDialog';
import VerifyDialogBeforeDownload from '@Components/VerifyDialogBeforeDownload';
import VerifyDialogAfterDownload from '@Components/VerifyDialogAfterDownload';
import { ActionBtn } from './Components';
import Hook from './hook';

const useStyles = makeStyles({
  centeredHeader: {
    '& span': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
});

const searchCategory = [
  { key: 'cycle', label: 'Cycle' },
  { key: 'year', label: 'Year' },
];

export default function Report(props) {
  const h = Hook(props);
  const classes = useStyles();
  const [data, setData] = useState([]);

  const columns = [
    {
      name: 'No',
      selector: 'id',
      options: {
        setCellHeaderProps: () => ({ style: { textAlign: 'left' } }),
        setCellProps: () => ({ style: { textAlign: 'left' } }),
        sort: false,
      },
    },
    {
      name: 'Report Name',
      selector: 'name',
      options: {
        setCellHeaderProps: () => ({ align: 'center' }),
        setCellProps: () => ({ align: 'center' }),
        sort: false,
      },
    },
    {
      name: 'Cycle',
      selector: 'cycle',
      options: {
        setCellHeaderProps: () => ({ align: 'center' }),
        setCellProps: () => ({ align: 'center' }),
        sort: false,
      },
    },
    {
      name: 'Year',
      selector: 'year',
      options: {
        setCellHeaderProps: () => ({ align: 'center' }),
        setCellProps: () => ({ align: 'center' }),
        sort: false,
      },
    },
    {
      name: 'Report Generated',
      selector: 'createdat',
      options: {
        setCellHeaderProps: () => ({ align: 'center' }),
        setCellProps: () => ({ align: 'center' }),
        sort: false,
      },
    },
    {
      name: 'Generated By',
      selector: 'userimage',
      options: {
        setCellHeaderProps: () => ({ align: 'center' }),
        setCellProps: () => ({ align: 'center' }),
        sort: false,
      },
    },
    {
      name: 'Verified By',
      selector: 'verification',
      options: {
        setCellHeaderProps: () => ({ align: 'center' }),
        setCellProps: () => ({ align: 'center' }),
        sort: false,
      },
    },
    {
      name: 'Archive Status',
      selector: 'archive_status',
      options: {
        setCellHeaderProps: () => ({ align: 'center' }),
        setCellProps: () => ({ align: 'center' }),
        sort: false,
        display: h.showDeleted === true,
      },
    },
    {
      name: 'Actions',
      selector: 'actions',
      options: {
        setCellHeaderProps: () => ({ align: 'center' }),
        setCellProps: () => ({ align: 'center' }),
        sort: false,
      },
    },
  ];

  const RestoreButton = ({ report, setOpen, setSelectedReport }) => {
    return (
      <Tooltip title="Restore Report" placement="top" onClick={() => { setSelectedReport(report); setOpen(true); }}>
        <IconButton style={{ width: 16, height: 16 }}>
          <img src={restoreIcon} />
        </IconButton>
      </Tooltip>
    );
  };

  const VerifyButton = ({ report, setOpen, setSelectedReport }) => {
    return (
      <Button
        variant="contained"
        style={{
          color: '#FFFFFF',
          borderRadius: 30,
          background: 'linear-gradient(var(--main-color), var(--primary-color))',
          width: '7rem',
        }}
        onClick={() => { setSelectedReport(report); setOpen(true); }}
      >
        <p className="text-white" style={{ cursor: 'pointer' }}>Verify</p>
      </Button>
    );
  };

  useEffect(() => {
    const reportData = h.reports.map((m, idx) => ({
      ...m,
      id: (h.page - 1) * h.perPage + idx + 1,
      createdat: moment(m.createdAt).format('DD MMM YYYY / HH:MM'),
      userimage: (
        <div className="flex-standard">
          {!!m.User ? <AvatarIcon user={m.User} size="2.5em" /> : <AccountCircle style={{ fontSize: '3em' }} />}
        </div>
      ),
      verification: m.verifiedBy
        ? (
          <div className="flex-standard">
            <AvatarIcon user={{ name: m.verifiedBy }} size="2.5em" />
          </div>
        )
        : (
          <VerifyButton
            report={m}
            setOpen={h.setOpenVerifyDialogBeforeDownload}
            setSelectedReport={h.setSelectedReport}
          />
        ),
      archive_status: !!m.deletedAt && moment(m.deletedAt).fromNow(),
      actions: !!m.deletedAt ? <RestoreButton report={m} setOpen={h.setOpenRestoreDialog} setSelectedReport={h.setSelectedReport} />
        : <ActionBtn report={m} setOpen={h.setOpenDialog} setSelectedReport={h.setSelectedReport} />,
    }));
    setData(reportData);
  }, [h.reports, h.downloaded]);

  return (
    <MainContentContainer>
      <div className="d-flex justify-content-between">
        {h.showDeleted ? <h1 className="color-primary">Deleted Report</h1> : (
          <Navbar
            to="/project/"
            text="Report"
          />
        )}
      </div>
      {h.isLoading ? <CenteredLoadingContainer height="50vh" size={75} hasText text="reports" /> : (
        <Grid container>
          <Grid item xs={12}>
            <PaginationV2 keysList={searchCategory} totalData={h.totalData} onRefresh={h.refresh} isReport {...h} />
          </Grid>
          <Grid item xs={12} style={{ padding: 20 }}>
            {data.length ? (
              <Table {...h} columns={columns} tableData={data} />
            ) : (
              <div className="d-flex justify-content-center">
                <img src={NoDataImg} style={{ width: '30vw' }} />
              </div>
            )}
          </Grid>
        </Grid>
      )}
      <DeleteDialog
        open={h.openDialog}
        setOpen={h.setOpenDialog}
        selected={h.selectedReport}
        setSelected={h.setSelectedReport}
        deleteFunction={() => h.onDelete()}
      />
      <VerifyDialogBeforeDownload
        openAlert={h.openVerifyDialogBeforeDownload}
        setOpenAlert={h.setOpenVerifyDialogBeforeDownload}
        openConfirmation={h.openVerifyDialogAfterDownload}
        setOpenConfirmation={h.setOpenVerifyDialogAfterDownload}
        selected={h.selectedReport}
        setSelected={h.setSelectedReport}
      />
      <VerifyDialogAfterDownload
        open={h.openVerifyDialogAfterDownload}
        setOpen={h.setOpenVerifyDialogAfterDownload}
        selected={h.selectedReport}
        setSelected={h.setSelectedReport}
        verifyFunction={() => h.onReportVerify()}
      />
      <RestoreDialog
        open={h.openRestoreDialog}
        setOpen={h.setOpenRestoreDialog}
        selected={h.selectedReport}
        setSelected={h.setSelectedReport}
        restoreFunction={() => h.onRestoreReport()}
      />
    </MainContentContainer>
  );
}
