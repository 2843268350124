/* eslint-disable complexity */
import { Grid } from '@material-ui/core';
import _ from 'lodash';

export default (h) => {
  return (
    <>
      {/* <h3 className="text-dark my-4">Asset Summary</h3> */}
      <Grid
        container
        className="pl-3 pt-3 position-relative color-gradient-inline hide-scroll"
        style={{ borderTopRightRadius: 10, borderBottomRightRadius: 10, height: '100%', paddingBottom: '40%', maxHeight: '100%', overflowY: 'auto' }}
      >
        {/* <Grid item xs={12}>
          <CustomLabelDataField title="ASSET ID" value={h.name} />
        </Grid> */}
        <Grid item xs={12} style={{ paddingTop: '30%' }}>
          <Grid item xs={12}>
            {/* <CustomLabelDataField title="ASSET ID" value={h.name} /> */}
            <p className="text-white my-2" style={{ fontSize: 12 }}>ASSET ID</p>
            <p className="mt-2 text-capitalize font-weight-bold text-white mr-2" style={{ fontSize: 16 }}>{h.name || '-'}</p>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid item xs={12}><CustomLabelDataField title="LOCALITY OF FEATURE" value={h.locality.label} /></Grid>
          <Grid item xs={12}><CustomLabelDataField title="LOCATION (KM)" value={h.locationInKM} /></Grid>
          <Grid item xs={12}><CustomLabelDataField title="BRIDGE TYPE" value={h.bridgeType.label} /></Grid>
          <Grid item xs={12}><CustomLabelDataField title="NO. OF SPAN" value={h.span} /></Grid>
          <Grid item xs={12}><CustomLabelDataField title="SKEW ANGLE" value={h.skewAngle} /></Grid>
        </Grid>
        <Grid item xs={6} className="pl-3">
          <Grid item xs={12}><CustomLabelDataField title="ROUTE" value={h.route.label} /></Grid>
          <Grid item xs={12}><CustomLabelDataField title="FEATURE NAME" value={h.featureName.label} /></Grid>
          <Grid item xs={12}><CustomLabelDataField title="BRIDGE OWNER" value={h.bridgeOwner.label} /></Grid>
          <Grid item xs={12}><CustomLabelDataField title="BRIDGE LENGTH" value={h.bridgeLength} /></Grid>
          <Grid item xs={12}><CustomLabelDataField title="CLEARANCE" value={h.clearance} /></Grid>
        </Grid>
      </Grid>
    </>
  );
};

const CustomLabelDataField = ({ title, value }) => (
  <>
    <p className="text-white my-2" style={{ fontSize: 12 }}>{title}</p>
    <p className="mt-2 mb-3 text-capitalize font-weight-bold text-white mr-2" style={{ fontSize: 16 }}>{value || '-'}</p>
  </>
);
