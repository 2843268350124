/* eslint-disable complexity */
/* eslint-disable max-len */
import React from 'react';
import {
  Typography, makeStyles, TextField, RadioGroup, FormControlLabel, Radio, MenuItem,
} from '@material-ui/core';
import Hook from './FormAnnotationHook';

const useStyles = makeStyles(() => ({
  radio: { margin: '-11px !important', padding: '1px !important' },
}));

export default function FormAnnotation(props) {
  const FAh = Hook(props);
  const classes = useStyles();
  return (
    [
      {
        title: 'Additional Info',
        children: <AdditionalInfoSection props={props} FAh={FAh} />,
        isShow: !![2].includes(props.assetTypeId),
      },
      {
        title: 'Defects Rating (AI)',
        children: (
          <div className="px-4 py-2">
            <div className="d-flex justify-content-between" style={{ fontSize: 14 }}>
              <p className="text-secondary">Detection:</p>
              <p style={{ fontSize: 16 }}>{!!props.annotation.ai_detection ? props.detectionTypeList.find(e => e.id === props.annotation.ai_detection)?.name : 'No Record'}</p>
            </div>
            <div className="d-flex justify-content-between" style={{ fontSize: 14 }}>
              <p className="text-secondary">Severity:</p>
              <p style={{ fontSize: 18, color: '#FEB019' }}>{!!props.annotation.ai_severity ? props.annotation.ai_severity : 'No Record'}</p>
            </div>
          </div>
        ),
        isShow: true,
      },
      {
        title: 'Detection',
        children: (
          <TextField
            select
            value={props.annotation?.DetectionTypeId ?? {
              1: 10, // initial detection id for asset type: slope which is 'Rock Dislodge'
              2: 15, // initial detection id for asset type: bridge which is 'Spalling Exposing Rebar'
            }[props.assetTypeId]}
            onChange={FAh.handleChangeDetectionType}
            variant="outlined"
            size="small"
          >
            {props.detectionTypeList.map(({ id, name }) => <MenuItem value={id}>{name}</MenuItem>)}
          </TextField>
        ),
        isShow: true,
      },
      {
        title: 'Severity',
        children: (
          <RadioGroup name="severity" row value={props.annotation?.SeverityId ?? 1} onChange={FAh.handleChangeSeverity} className="d-flex justify-content-around pt-2 mb-3">
            {props.severityList.map((e) => (
              <FormControlLabel
                className={classes.radio}
                value={e.id}
                control={<Radio />}
                label={<Typography style={{ fontSize: '10px', color: '#022C64' }}>{e.name}</Typography>}
                labelPlacement="bottom"
              />
            ))}
          </RadioGroup>
        ),
        isShow: true,
      },
      {
        title: 'Remark By Engineer',
        children: (
          <TextField
            placeholder="Description Here"
            multiline
            rows={5}
            variant="outlined"
            className="w-100 py-2"
            value={props.annotation?.description ?? ''}
            onChange={FAh.handleChangeDescription}
          />
        ),
        isShow: true,
      },
    ].map(form => (
      form.isShow && (
        <>
          <div style={{ backgroundColor: '#022C64', padding: 10 }}>
            <p className="text-white">{form.title}</p>
          </div>
          <div className="px-4 py-2">
            {form.children}
          </div>
        </>
      )
    ))
  );
}

const AdditionalInfoSection = ({ props, FAh }) => {
  return (
    <div className="px-4 py-2">
      <div className="py-2" style={{ fontSize: 14 }}>
        <p className="text-secondary mb-1">Bridge Direction:</p>
        <TextField
          select
          value={props.annotation?.bridge_direction ?? 'NB'}
          onChange={e => FAh.handleChangeAdditionalInfo(e.target.value, 'bridge_direction')}
          variant="outlined"
          size="small"
          style={{ width: '100%' }}
        >
          <MenuItem value="NB">NB</MenuItem>
          <MenuItem value="SB">SB</MenuItem>
          <MenuItem value="EB">EB</MenuItem>
          <MenuItem value="WB">WB</MenuItem>
        </TextField>
      </div>
      <div className="py-2" style={{ fontSize: 14 }}>
        <p className="text-secondary mb-1">Beam Number:</p>
        <CustomTextField
          value={props.annotation?.beam_number ?? ''}
          onChange={e => FAh.handleChangeAdditionalInfo(e.target.value, 'beam_number')}
          placeholder="e.g N1, S1, E1, W1"
        />
      </div>
      <div className="py-2" style={{ fontSize: 14 }}>
        <p className="text-secondary mb-1">Span Number:</p>
        <CustomTextField
          value={props.annotation?.span_number ?? ''}
          onChange={e => FAh.handleChangeAdditionalInfo(e.target.value, 'span_number')}
          placeholder="e.g N1, S1, E1, W1"
        />
      </div>
      <div className="py-2" style={{ fontSize: 14 }}>
        <p className="text-secondary mb-1">Joint Number:</p>
        <CustomTextField
          value={props.annotation?.joint_number ?? ''}
          onChange={e => FAh.handleChangeAdditionalInfo(e.target.value, 'joint_number')}
          placeholder="e.g N1, S1, E1, W1"
        />
      </div>
      <div className="py-2" style={{ fontSize: 14 }}>
        <p className="text-secondary mb-1">Pier Number:</p>
        <CustomTextField
          value={props.annotation?.pier_number ?? ''}
          onChange={e => FAh.handleChangeAdditionalInfo(e.target.value, 'pier_number')}
          placeholder="e.g N1, S1, E1, W1"
        />
      </div>
      <div className="py-2" style={{ fontSize: 14 }}>
        <p className="text-secondary mb-1">Diaphragm Number:</p>
        <CustomTextField
          value={props.annotation?.diaphragm_number ?? ''}
          onChange={e => FAh.handleChangeAdditionalInfo(e.target.value, 'diaphragm_number')}
          placeholder="e.g ND1, SD1, ED1, WD1"
        />
      </div>
      <div className="py-2" style={{ fontSize: 14 }}>
        <p className="text-secondary mb-1">Bearing Number:</p>
        <CustomTextField
          value={props.annotation?.bearing_number ?? ''}
          onChange={e => FAh.handleChangeAdditionalInfo(e.target.value, 'bearing_number')}
          placeholder="e.g N1, S1, E1, W1"
        />
      </div>
      <div className="py-2" style={{ fontSize: 14 }}>
        <p className="text-secondary mb-1">Abutment Number:</p>
        <CustomTextField
          value={props.annotation?.abutment_number ?? ''}
          onChange={e => FAh.handleChangeAdditionalInfo(e.target.value, 'abutment_number')}
          placeholder="e.g A or B"
        />
      </div>
    </div>
  );
};

const CustomTextField = (props) => <TextField {...props} variant="outlined" size="small" className="w-100" />;
