/* eslint-disable complexity */
import { useState, useEffect } from 'react';
import { Paper, Grid } from '@material-ui/core';
import Dropdown from '@Components/Dropdown';
import GradientTabs from '@Components/GradientTabs';
import Button from '@Components/Button';
import Card from '@Components/CustomCard3';
import StatisticTable from '@Components/MaterialTable/StatisticTable';
import BarChart from '@Components/BarChart/v6';
import FilterTag from '@Components/FilterTag';
import CenteredLoadingContainer from '@Components/CenteredLoadingContainer';
import NoData from '@Assets/Images/Data-not-found3.png';
import useHook from './hook';

const tableStyle = { align: 'right', style: { color: 'var(--dark-blue-color)', fontSize: 14 } };

export default function Analytics(props) {
  const h = useHook(props);
  const [tabs, setTabs] = useState([]);
  const [legend, setLegend] = useState(null);
  const [filter, setFilter] = useState(<></>);
  let content = null;
  const Filter = () => filter;

  useEffect(() => {
    if (h.pageCondition === 'slope') {
      setTabs([
        { label: 'SLOPE CONDITION BY NETWORK', selector: 0, tableFor: 'Network' },
        { label: 'SLOPE CONDITION BY SECTION', selector: 1, tableFor: 'Section' },
        { label: 'SLOPE HAZARD & RISK RANKING', selector: 2, tableFor: 'Slope' },
      ]);
    } else if (h.pageCondition === 'bridge') {
      setTabs([
        { label: 'BRIDGE CONDITION BY NETWORK', selector: 0, tableFor: 'Network' },
        { label: 'BRIDGE CONDITION BY SECTION', selector: 1, tableFor: 'Condition' },
        { label: 'BRIDGE SEVERITY BY SECTION', selector: 2, tableFor: 'Severity' },
        { label: 'BRIDGE TYPE', selector: 3, tableFor: 'Type' },
      ]);
    }
  }, [h.pageCondition]);

  useEffect(() => {
    if (h.pageCondition === 'slope') {
      setLegend([0, 1].includes(h.tab) ? h.staticCondition : h.staticRank);
      if ([0, 1].includes(h.tab)) {
        setFilter(
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FilterTag tags={h.tags} tag={h.tagsFilter} setTag={h.setTagsFilter} />
            </Grid>
          </Grid>,
        );
      } else {
        setFilter(<></>);
      }
    } else {
      setLegend([0, 1, 2].includes(h.tab) ? h.staticCondition : h.staticType);
      if ([0, 1, 2].includes(h.tab)) {
        setFilter(
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FilterTag tags={h.tags} tag={h.tagsFilter} setTag={h.setTagsFilter} />
            </Grid>
          </Grid>,
        );
      } else {
        setFilter(<></>);
      }
    }
  }, [h.tagsFilter]);

  if (tabs.length > 0) {
    if (h.isLoading) {
      content = (
        <CenteredLoadingContainer height="50vh" size={75} hasText text="analytics" />
      );
    } else {
      content = (
        <>
          <GradientTabs tabs={tabs} tab={h.tab} setTab={h.setTab} />
          <br />
          <Filter />
          {/*
      for debugging
      <Paper>
        filter tag{h.tagsFilter}

        <br />
        year{h.yearIdx}
        <br />
        cycle{h.cycleIdx}
        <br />
        tab {h.tab}
      </Paper> */}

          {h.barData && (
            <Paper>
              <BarChart
                tab={h.tab}
                topLabel={tabs[h.tab]?.label}
                topLabelStyles={{ x: (tabs[h.tab].label?.split(' ').length > 2) ? 400 : 500, y: 20 }}
                colorfills={['#7ad5b0', '#468ef7', '#f0b334', '#d4371b', '#8d95aa']}
                // data={h.barData.map(bd => ({ ...bd, ...bd.ann }))}
                data={h.barData.map(bd => ({ ...bd, ...bd.slope }))}
                legend={legend}
                legendStyles={{ x: 400, y: 40 }}
              />
            </Paper>
          )}
          {[0, 1].includes(h.tab) && (
            <Card
              className="p-2"
              noAnimation
              isToTheSide={12}
              adjustStyle={{ border: '1px solid rgba(0, 0, 0, 0.1)', boxShadow: '0px', minHeight: '30vh' }}
              hoverColor="#30A6D3"
            >
              <StatisticTable
                tableHead
                columns={[
                  { selector: 'col_idx', name: tabs[h.tab].tableFor },
                  ...h.staticCondition,
                  { selector: 't', name: 'TOTAL' },
                ].map((ec, idx) => ({ ...ec, ...tableStyle, align: idx ? 'right' : 'left' }))}
                tableData={h.tableData}
                tableMaxHeight="-webkit-fill-available"
              />
            </Card>
          )}{[2].includes(h.tab) && (
            <>
              {h.tableData.network && (
                <Card
                  className="p-2"
                  noAnimation
                  isToTheSide={12}
                  adjustStyle={{ border: '1px solid rgba(0, 0, 0, 0.1)', boxShadow: '0px', minHeight: '30vh' }}
                  hoverColor="#30A6D3"
                >
                  <StatisticTable
                    tableHead
                    columns={[
                      { selector: 'col_idx', name: tabs[0].tableFor },
                      ...h.staticRank,
                      { selector: 't', name: 'TOTAL' },
                    ].map((ec, idx) => ({ ...ec, ...tableStyle, align: idx ? 'right' : 'left' }))}
                    tableData={h.tableData?.network}
                    tableMaxHeight="-webkit-fill-available"
                  />
                </Card>
              )}
              {h.tableData.section && (
                <Card
                  className="p-2"
                  noAnimation
                  isToTheSide={12}
                  adjustStyle={{ border: '1px solid rgba(0, 0, 0, 0.1)', boxShadow: '0px', minHeight: '30vh' }}
                  hoverColor="#30A6D3"
                >
                  <StatisticTable
                    tableHead
                    columns={[
                      { selector: 'col_idx', name: tabs[1].tableFor },
                      ...h.staticRank,
                      { selector: 't', name: 'TOTAL' },
                    ].map((ec, idx) => ({ ...ec, ...tableStyle, align: idx ? 'right' : 'left' }))}
                    tableData={h.tableData?.section}
                    tableMaxHeight="-webkit-fill-available"
                  />
                </Card>
              )}
            </>
          )}
        </>
      );
    }
  } else {
    content = (
      <div className="d-flex justify-content-center w-100 m-4 p-5">
        <img src={NoData} style={{ height: '55vh' }} />
      </div>
    );
  }

  return (
    <div className="main-padding">
      <div className="d-flex mb-2 justify-content-between align-items-center">
        <h1 className="text-title">Analytics</h1>
        {/* <Button className="color-gradient-inline px-3" style={{ borderRadius: 50 }}>EXPORT AS PDF</Button> */}
      </div>
      {content}
    </div>
  );
}
