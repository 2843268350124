/* eslint-disable max-lines */
import _ from 'lodash';
import { useEffect, useState } from 'react';
import Api, { endpoints } from '@Helpers/api';
import { useParams } from 'react-router-dom';

export default () => {
  const { AssetId } = useParams();
  const [assetTypeList, setAssetTypeList] = useState([]);
  const [assetType, setAssetType] = useState('');
  const [asset, setAsset] = useState('');
  const [name, setName] = useState('');
  const [marker, setMarker] = useState(null);
  const [polygon, setPolygon] = useState(null);
  const [files, setFiles] = useState([]);
  const [region, setRegion] = useState(null);
  const [section, setSection] = useState(null);
  const [network, setNetwork] = useState([]);
  const [ranking, setRanking] = useState([]);
  const [regions, setRegions] = useState([]);
  const [sections, setSections] = useState([]);
  const [networks, setNetworks] = useState([]);
  const [rankings, setRankings] = useState([]);
  const [locality, setLocality] = useState([]);
  const [localities, setLocalities] = useState([]);
  const [route, setRoute] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [locationInKM, setLocationInKM] = useState([]);
  const [featureName, setFeatureName] = useState([]);
  const [featureNames, setFeatureNames] = useState([]);
  const [bridgeType, setBridgeType] = useState([]);
  const [bridgeTypes, setBridgeTypes] = useState([]);
  const [bridgeOwner, setBridgeOwner] = useState([]);
  const [bridgeOwners, setBridgeOwners] = useState([]);
  const [span, setSpan] = useState([]);
  const [bridgeLength, setBridgeLength] = useState([]);
  const [skewAngle, setSkewAngle] = useState([]);
  const [clearance, setClearance] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getStaticData();
    Api({
      endpoint: endpoints.showAssets(AssetId),
      onSuccess: ({ data }) => {
        setAsset(data);
        setName(data.name);
        setMarker({ lat: data.lat, lng: data.lng });
        setNetwork(data.NetworkId);
        setRanking(data.RankingId);
        setSection(data.SectionId);
        setRegion(data.RegionId);
        setPolygon(JSON.parse(data.polygon));
        setAssetType(data.AssetTypeId);
        setLocality(data.locality_of_feature);
        setRoute(data.route);
        setLocationInKM(data.km_location);
        setFeatureName(data.feature_name);
        setBridgeType(data.bridge_type);
        setBridgeOwner(data.bridge_owner);
        setSpan(data.no_span);
        setBridgeLength(data.total_bridge_length);
        setSkewAngle(data.skew_angle);
        setClearance(data.clearance);
        setIsLoading(false);
      },
      onFail: () => { toast('error', 'Failed to get asset data'); },
    });
  }, []);

  const updateAsset = () => {
    let input;
    switch (assetType) {
      case 1:
        input = {
          name,
          AssetTypeId: assetType,
          NetworkId: network,
          RankingId: ranking,
          polygon: JSON.stringify(polygon),
          lat: marker.lat,
          lng: marker.lng,
        };
        break;
      case 2:
        input = {
          name,
          AssetTypeId: assetType,
          NetworkId: network,
          // RankingId: ranking.value,
          polygon: JSON.stringify(polygon),
          lat: marker.lat,
          lng: marker.lng,
          // SlopeConditionId: section,
          locality_of_feature: locality,
          feature_name: featureName,
          route,
          km_location: parseInt(locationInKM),
          bridge_type: bridgeType,
          bridge_owner: bridgeOwner,
          no_span: parseInt(span),
          total_bridge_length: parseInt(bridgeLength),
          skew_angle: parseInt(skewAngle),
          clearance: parseInt(clearance),
        };
        break;
      default:
        return;
    }
    input.RegionId = region ?? null;
    input.SectionId = section ?? null;
    if (!input.name) return;
    if (!input.NetworkId) return;
    setIsLoading(true);
    Api({
      endpoint: endpoints.updateAssets(AssetId),
      data: { input },
      files,
      onSuccess: () => {
        toast('success', 'Asset updated');
        window.location.replace('/asset');
      },
      onFail: () => {
        toast('error', 'Opss, something went wrong, please try again.');
        window.location.replace('/asset');
      },
    });
  };

  const selectedTypeProfile = _.find(assetTypeList, { id: asset.AssetTypeId });

  const getStaticData = () => {
    Api({
      endpoint: endpoints.getStaticData(),
      onSuccess: ({ data }) => {
        setAssetTypeList(data.AssetType ?? []);
        setNetworks(data.Network.map(m => ({ label: m.name, value: m.id })));
        setRegions(data.Region.map(m => ({ label: m.name, value: m.id })));
        setSections(data.Section.map(m => ({ label: m.name, value: m.id })));
        setRankings(data.Ranking.map(m => ({ label: m.name, value: m.id })));
        setLocalities(data.LocalityOfFeature.map(m => ({ label: m.name, value: m.id })));
        setRoutes(data.Route.map(m => ({ label: m.name, value: m.id })));
        setFeatureNames(data.FeatureName.map(m => ({ label: m.name, value: m.id })));
        setBridgeTypes(data.BridgeType.map(m => ({ label: m.name, value: m.id })));
        setBridgeOwners(data.BridgeOwner.map(m => ({ label: m.name, value: m.id })));
      },
      onFail: () => console.log('error loading static data'),
    });
  };

  return {
    assetType,
    setAssetType,
    assetTypeList,
    selectedTypeProfile,
    name,
    setName,
    marker,
    setMarker,
    polygon,
    setPolygon,
    files,
    setFiles,
    isSuccess,
    setIsSuccess,
    isLoading,
    updateAsset,
    asset,
    network,
    setNetwork,
    region,
    setRegion,
    section,
    setSection,
    ranking,
    setRanking,
    networks,
    regions,
    sections,
    rankings,
    locality,
    setLocality,
    bridgeOwner,
    setBridgeOwner,
    bridgeOwners,
    setBridgeOwners,
    span,
    setSpan,
    bridgeLength,
    setBridgeLength,
    skewAngle,
    setSkewAngle,
    clearance,
    setClearance,
    localities,
    setLocalities,
    route,
    setRoute,
    routes,
    setRoutes,
    locationInKM,
    setLocationInKM,
    featureName,
    setFeatureName,
    featureNames,
    setFeatureNames,
    bridgeType,
    setBridgeType,
    bridgeTypes,
    setBridgeTypes,
  };
};
