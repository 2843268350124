import React, { useState, useEffect } from 'react';
import {
  Grid, TextField, Chip, InputAdornment, IconButton,
} from '@material-ui/core';
import EnterIcon from '@material-ui/icons/KeyboardReturn';

import { makeStyles } from '@material-ui/core/styles';
import Downshift from 'downshift';

const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(0.5, 0.25),
    backgroundColor: '#DBEFFF',
    textWeight: 600,
    color: 'black',
    '& .MuiChip-deleteIcon': {
      color: 'black',
    },
  },
}));

export default function InspectionReminderInput({
  setEmail, email, placeholder, emails = [],
}) {
  const classes = useStyles();
  const [inputValue, setInputValue] = React.useState('');
  const [selectedItem, setSelectedItem] = React.useState(!!email.length ? email.split(',') : []);
  useEffect(() => {
    if (!emails.length) return;
    setSelectedItem(emails);
  }, [emails]);

  useEffect(() => {
    if (!selectedItem.length);
    setEmail(selectedItem.toString());
  }, [selectedItem, email]);

  function handleKeyDown(event) {
    if (event.key === 'Enter') {
      const newSelectedItem = [...selectedItem];
      const duplicatedValues = newSelectedItem.indexOf(
        event.target.value.trim(),
      );

      if (duplicatedValues !== -1) {
        setInputValue('');
        return;
      }
      if (!event.target.value.replace(/\s/g, '').length) return;

      newSelectedItem.push(event.target.value.trim());
      setSelectedItem(newSelectedItem);
      setInputValue('');
    }
    if (
      selectedItem.length
      && !inputValue.length
      && event.key === 'Backspace'
    ) {
      setSelectedItem(selectedItem.slice(0, selectedItem.length - 1));
    }
  }
  function handleChange(item) {
    let newSelectedItem = [...selectedItem];
    if (newSelectedItem.indexOf(item) === -1) {
      newSelectedItem = [...newSelectedItem, item];
    }
    setInputValue('');
    setSelectedItem(newSelectedItem);
  }

  const handleDelete = item => () => {
    const newSelectedItem = [...selectedItem];
    newSelectedItem.splice(newSelectedItem.indexOf(item), 1);
    setSelectedItem(newSelectedItem);
  };

  function handleInputChange(event) {
    setInputValue(event.target.value);
  }
  return (
    <>
      <h1 style={{ fontSize: 20 }}>Share Next Inspection Reminder</h1>
      <p style={{ fontSize: 14, color: 'grey' }}>Send an email of next insecton reinder to assigned teammates.</p>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Grid container>
          <Grid item container style={{ display: 'flex', alignItems: 'center' }}>
            <Grid item xs={3}>
              <p style={{ padding: '15px 10px' }}>Email Address</p>
            </Grid>
            <Grid item xs={9}>
              <Downshift
                id="downshift-multiple"
                inputValue={inputValue}
                onChange={handleChange}
                selectedItem={selectedItem}
              >
                {({ getInputProps }) => {
                  const {
                    onBlur, onChange, onFocus, ...inputProps
                  } = getInputProps({
                    onKeyDown: handleKeyDown,
                    placeholder,
                  });
                  return (
                    <div>
                      <TextField
                        size="small"
                        variant="outlined"
                        type="email"
                        fullWidth
                        InputProps={{
                          onBlur,
                          onChange: event => {
                            handleInputChange(event);
                            onChange(event);
                          },
                          onFocus,
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton>
                                <EnterIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        {...inputProps}
                      />
                    </div>
                  );
                }}
              </Downshift>
              {!!inputValue
                && (
                  <p style={{ textAlign: 'end', fontSize: '12px', color: 'grey' }}>
                    Please click 'Enter' key to add the email
                  </p>
                )}
              {/* <TextField
                size="small"
                fullWidth
                variant="outlined"
                value={props.emails}
                onChange={(e) => props.setEmails(e.target.value)}
              /> */}
            </Grid>
            {/* <Grid item xs={3}>
              <Box
                component="button"
                className="blue-button-outlined"
                onClick={() => handleKeyDown('click')}
                style={{
                  borderRadius: '6px', width: '80%', marginLeft: 20, height: '35px',
                }}
              >
                <h1 style={{
                  color: 'var(--primary-color)', fontSize: 16, fontWeight: 600, cursor: 'pointer',
                }}
                >
                  Enter email
                </h1>
              </Box>
            </Grid> */}
            <Grid item xs={3} />
            <Grid item xs={6} style={{ maxHeight: '4em', overflowY: 'auto' }}>
              {selectedItem?.map(item => (
                <Chip
                  key={item}
                  tabIndex={-1}
                  size="small"
                  label={item}
                  className={`${classes.chip}`}
                  onDelete={handleDelete(item)}
                />
              ))}
            </Grid>
            <Grid item xs={3} />
          </Grid>
        </Grid>
      </div>
    </>
  );
}
