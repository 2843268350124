import React from 'react';
import {
  DialogTitle, Dialog, DialogContent, DialogActions, MenuItem, IconButton, TextField, Select, InputLabel,
} from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import Button from '@Components/Button';

export default function Upload3DDialog({
  open, setOpen, uploadFunction = () => null, name, url, categoryList, setName, setURL, category, setCategory,
}) {
  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
        <div className="d-flex justify-content-between align-items-center">
          <DialogTitle>
            <p style={{ color: 'var(--dark-blue-color)', fontWeight: 600, fontSize: 20 }}>Upload Document</p>
          </DialogTitle>
          <IconButton style={{ backgroundColor: 'transparent', padding: 0 }}>
            <Close style={{ color: 'grey', padding: 20 }} onClick={() => setOpen(false)} />
          </IconButton>
        </div>
        <DialogContent style={{ textAlign: 'center' }}>
          {[
            {
              inputLabel: '3D Model Name', value: name, placeholder: 'Enter name here', setValue: setName,
            },
            {
              inputLabel: 'URL', value: url, placeholder: 'Enter url link here', setValue: setURL,
            },
          ].map(d => (
            <div style={{ width: '100%', marginTop: 20 }}>
              <p style={{
                display: 'flex', flex: 'start', marginBottom: 5, color: 'var(--dark-blue-color)',
              }}
              >{d.inputLabel}
              </p>
              <TextField
                {...d}
                placeholder={d.placeholder}
                variant="outlined"
                fullWidth
                value={d.value}
                onChange={(e) => d.setValue(e.target.value)}
              />
            </div>
          ))}
          <div style={{ width: '100%', marginTop: 20 }}>
            <p style={{
              display: 'flex', flex: 'start', marginBottom: 5, color: 'var(--dark-blue-color)',
            }}
            >Category
            </p>
            <Select
              variant="outlined"
              placeholder="Please select category"
              value={category}
              style={{
                padding: '0px', width: '100%', textAlign: 'start', color: 'black',
              }}
              onChange={(e) => setCategory(e.target.value)}
            >
              <InputLabel id="demo-controlled-open-select-label" style={{ paddingInline: '15px' }}>Select category</InputLabel>
              {categoryList.map((c) => (<MenuItem value={c.id}>{c.name}</MenuItem>))}
            </Select>
          </div>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'end', marginBlock: 10, marginRight: 20 }}>
          <Button
            variant="contained"
            style={{
              color: 'var(--primary-color)',
              width: '10rem',
              outline: '1px solid var(--primary-color)',
              backgroundColor: 'transparent',
              borderRadius: 30,
            }}
            onClick={() => setOpen(false)}
          >
            <p className="ml-2">Cancel</p>
          </Button>
          <Button
            variant="contained"
            style={{
              color: '#FFFFFF',
              borderRadius: 30,
              background: 'linear-gradient(var(--main-color), var(--primary-color))',
              width: '10rem',
            }}
            onClick={uploadFunction}
          >
            <p className="text-white ml-2">Upload</p>
          </Button>
        </DialogActions>
      </Dialog>
    </MuiThemeProvider>
  );
}

const getMuiTheme = () => createMuiTheme({
  typography: {
    fontFamily: 'CeraProRegular',
  },
});
