/* eslint-disable max-len */
import React, { useEffect, useState, useRef } from 'react';
import lottie from 'lottie-web';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

export default function VerifyDialogAfterDownload({
  open = false,
  setOpen = () => null,
  selected = {},
  setSelected = () => null,
  verifyFunction = () => null,
}) {
  const click = useRef(null);
  const container = useRef(null);
  const [isShow, setIsShow] = useState(true);
  const [doneCycle, setDoneCycle] = useState(false);
  const handleClose = () => { if (!!selected) setSelected(); setOpen(false); setDoneCycle(false); setIsShow(true); };
  const handleVerify = () => {
    verifyFunction(selected.id);
    handleClose();
  };
  const refresh = () => click.current?.click();

  useEffect(() => {
    if (!open) return;
    if (doneCycle) return;
    lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: require('@Assets/Icons/question-lottie.json'),
    });
    setIsShow(false);
  }, [open, isShow]);

  useEffect(() => {
    if (isShow) return;
    setTimeout(refresh, 200);
  }, [isShow]);

  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent style={{ textAlign: 'center' }}>
          <div className="d-flex flex-column font-weight-normal">
            <div className="d-flex justify-content-center mx-auto" ref={container} style={{ marginTop: -20, height: '60%', width: '60%' }} />
            <p>Verifying the report indicates that you have thoroughly reviewed the contents and confirm its accuracy.</p>
            <p>Are you sure you want to verify this report?</p>
          </div>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center', marginTop: 10, marginBottom: 10 }}>
          <Button
            onClick={handleClose}
            variant="contained"
            style={{
              color: 'var(--primary-color)',
              width: '10rem',
              outline: '1px solid var(--primary-color)',
              backgroundColor: 'transparent',
              borderRadius: 30,
            }}
          >
            <p>
              No
            </p>
          </Button>
          <Button
            variant="contained"
            style={{
              color: '#FFFFFF',
              borderRadius: 30,
              background: 'linear-gradient(var(--main-color), var(--primary-color))',
              width: '10rem',
            }}
            onClick={handleVerify}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </MuiThemeProvider>
  );
}

const getMuiTheme = () => createMuiTheme({
  typography: {
    fontFamily: 'CeraProRegular',
  },
});
