import { useEffect, useRef, useState } from 'react';
import Api, { endpoints } from '@Helpers/api';
import _ from 'lodash';
import { capitalize, sliceIntoChunks } from '@Helpers';
import { useLocation } from 'react-router-dom'

export default function Hook() {
  const location = useLocation();
  const pageCondition = !!location.pathname.split('/')[2] ? location.pathname.split('/')[2] : 'Slope';
  const [allProjects, setAllProjects] = useState([]);
  const [filterProjects, setFilterProjects] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState();
  const [open, setOpen] = useState(false);
  const [openCardDialog, setOpenCardDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tagsFilter, setTagsFilter] = useState(['']);
  const [openRelative, setOpenRelative] = useState(false);
  const [workflowAccess, setWorkflowAccess] = useState([]);
  const [teamAccess, setTeamAccess] = useState([]);
  const [userAccess, setUserAccess] = useState([]);
  const [networks, setNetworks] = useState([]);
  const [sections, setSections] = useState([]);
  const [regions, setRegions] = useState([]);
  const [rankings, setRankings] = useState([]);
  const [bridgeType, setBridgeType] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState([]);
  const [selectedSection, setSelectedSection] = useState([]);
  const [selectedRanking, setSelectedRanking] = useState([]);
  const [selectedBridgeType, setSelectedBridgeType] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(8);
  const [splitAssetPerPage, setSplitAssetPerPage] = useState([]);
  const [totalFilteredAsset, setTotalFilteredAsset] = useState(0);
  const [title, setTitle] = useState('');
  const customFilterByType = pageCondition === 'slope' ? { RankingId: selectedRanking } : { bridge_type: selectedBridgeType }
  const filterType = {
    RegionId: selectedRegion, NetworkId: selectedNetwork, SectionId: selectedSection, ...customFilterByType,
  };
  const anchorRef = useRef(null);

  useEffect(() => {
    if (!pageCondition) setTitle('Slope');
    setTitle(capitalize(pageCondition));
  }, [pageCondition])

  useEffect(() => {
    getStaticData();
  }, []);

  useEffect(() => {
    refresh();
  }, [pageCondition]);

  const refresh = () => {
    setIsLoading(true);
    Api({
      endpoint: endpoints.getAssets(),
      onSuccess: ({ data }) => {
        const dataSorted = data.sort((a, b) => b.createdAt.localeCompare(a.createdAt)).filter(e => e.AssetTypeId === (pageCondition === 'slope' ? 1 : 2));
        setAllProjects(dataSorted);
        setSplitAssetPerPage(sliceIntoChunks(dataSorted, perPage));
        setIsLoading(false);
      },
      onFail: (err) => { toast('error', err); setIsLoading(false); },
    });
  };

  const getStaticData = () => {
    Api({
      endpoint: endpoints.getStaticData(),
      onSuccess: ({ data }) => {
        setNetworks(data.Network.map(m => ({ label: m.name, value: m.id })));
        setRegions(data.Region.map(m => ({ label: m.name, value: m.id })));
        setSections(data.Section.map(m => ({ label: m.name, value: m.id })));
        setRankings(data.Ranking.map(m => ({ label: m.name, value: m.id })));
        setBridgeType(data.BridgeType.map(m => ({ label: m.name, value: String(m.id) })));
      },
      onFail: (err) => toast('error', err),
    });
  };

  const deleteAsset = () => {
    if (!selectedAsset.id) return;
    setIsLoading(true);
    Api({
      endpoint: endpoints.deleteAssets(selectedAsset.id),
      onSuccess: () => {
        refresh();
        toast('success', 'Successfully deleted');
      },
      onFail: (err) => { toast('error', err); setIsLoading(false); },
    });
  };

  const searchKeyword = (array) => {
    if (!keyword) {
      setSplitAssetPerPage(sliceIntoChunks(array, perPage));
      setTotalFilteredAsset(array.length);
    } else {
      const result = array?.filter((data) => {
        return data.name?.toLowerCase().search(keyword) != -1;
      });
      setSplitAssetPerPage(sliceIntoChunks(result, perPage));
      setTotalFilteredAsset(result.length);
    }
  };

  useEffect(() => {
    if (!allProjects.length) return;
    const hasFilterType = (Object.keys(filterType).map(m => filterType[m].length)).filter(f => !!f);
    const selectedFilteredType = [];
    Object.keys(filterType).forEach(m => {
      if (filterType[m].length) selectedFilteredType.push({ [m]: filterType[m] });
    });
    const filteredTypeObj = Object.assign({}, ...selectedFilteredType);
    setPage(1);
    if (!hasFilterType.length) {
      searchKeyword(allProjects);
    } else {
      const result = allProjects.filter(project => {
        // eslint-disable-next-line no-restricted-syntax
        for (const key in filteredTypeObj) {
          if (project[key] === undefined || !filteredTypeObj[key].includes(project[key])) return false;
        }
        return true;
      });
      searchKeyword(_.uniq(result.flat()));
    }
  }, [allProjects, keyword, selectedNetwork, selectedRegion, selectedSection, selectedRanking, selectedBridgeType, perPage]);

  useEffect(() => {
    if (!splitAssetPerPage.length) return setFilterProjects([]);
    setFilterProjects(splitAssetPerPage[page - 1]);
  }, [splitAssetPerPage, page]);

  return {
    allProjects,
    deleteAsset,
    selectedAsset,
    setSelectedAsset,
    open,
    setOpen,
    isLoading,
    anchorRef,
    tagsFilter,
    setTagsFilter,
    filterProjects,
    openRelative,
    setOpenRelative,
    workflowAccess,
    setWorkflowAccess,
    teamAccess,
    setTeamAccess,
    userAccess,
    setUserAccess,
    refreshAsset: refresh,
    networks,
    regions,
    sections,
    rankings,
    bridgeType,
    selectedNetwork,
    setSelectedNetwork,
    selectedRegion,
    setSelectedRegion,
    selectedSection,
    setSelectedSection,
    selectedRanking,
    setSelectedRanking,
    selectedBridgeType,
    setSelectedBridgeType,
    openCardDialog,
    setOpenCardDialog,
    setKeyword,
    page,
    setPage,
    perPage,
    setPerPage,
    totalFilteredAsset,
    pageCondition,
    title,
  };
}
