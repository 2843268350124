import { IconButton, Tooltip } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { DownloadIconV2 } from '@Assets/Icons';
import { Link } from 'react-router-dom';

export const ActionBtn = (props) => {
  return (
    <div className="d-flex justify-content-around align-items-center" style={{ gap: 0 }}>
      <Link to={{ pathname: `${props.modellingFile.path}` }} target="_blank">
        <Tooltip title="Download File" placement="top">
          <IconButton style={{ width: 16, height: 16 }}>
            <DownloadIconV2 />
          </IconButton>
        </Tooltip>
      </Link>
      <DeleteButton {...props} />
    </div>
  );
};

const DeleteButton = ({ modellingFile, setOpen, setSelectedModellingFile }) => (
  <Tooltip title="Delete File" placement="top">
    <IconButton style={{ width: 16, height: 16 }} onClick={() => { setSelectedModellingFile(modellingFile); setOpen(true); }}>
      <Delete style={{ color: 'red' }} />
    </IconButton>
  </Tooltip>
);
